import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, ButtonBase, CardContent, ClickAwayListener, Grid2, Paper, Popper, Stack, Typography } from '@mui/material';

// project import
import MainCard from 'components/third-party/MainCard';

// assets
import UserLogo from 'assets/images/icons/navigation/PeopleFilled.svg';
import { dispatch, useSelector } from 'store';
import useAuth from 'hooks/useAuth';
import { setTabId } from 'store/reducers/helpers';
import { Avatar } from '@mui/material';
import Transitions from 'components/custom/Transitions';

// ==============================|| HEADER CONTENT - PROFILE ||============================== /

const Profile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { logout } = useAuth();

  // funzione di gestione del logout
  const handleLogout = async () => {
    try {
      await logout();
      navigate(`/`, {
        state: {
          from: ''
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // funzione di navigazione al proprio profilo
  const navToProfile = () => {
    navigate('collaborators/me');
    dispatch(setTabId({ tabId: 0 }));
    setOpen(false);
  };

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';

  return (
    <Box sx={{ flexShrink: 0, mr: 2 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter' },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5, zIndex: 1000 }}>
          <Avatar alt="profile user" src={UserLogo} sx={{ width: 24, height: 24, mb: 0.5, zIndex: 1000 }} />
          <Typography variant="subtitle1">
            {user?.firstname} {user?.lastname}
          </Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        transition
        sx={{ zIndex: 1500, position: 'absolute' }}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 3]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position="top-right" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: 100,
                minWidth: 150,
                maxWidth: 290,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 250
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} content={false}>
                  <CardContent sx={{ px: 2.5, pt: 3 }}>
                    <Grid2 container width="100%" justifyContent="space-between" alignItems="center">
                      <Grid2>
                        {/* pulsante che naviga al profilo */}
                        <Typography variant="body2" sx={{ cursor: 'pointer' }} onClick={navToProfile}>
                          Profilo
                        </Typography>
                        {/* pulsante che forza il logout */}
                        <Typography variant="body2" sx={{ mt: 1, cursor: 'pointer' }} onClick={handleLogout}>
                          Esci
                        </Typography>
                      </Grid2>
                    </Grid2>
                  </CardContent>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
