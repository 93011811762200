import { Avatar, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import useModal from 'hooks/useModal';
import { SendTaskDTO } from 'types/dto/task.dto';
import { useSelector } from 'store';
import { FormikErrors, useFormik } from 'formik';
import { AskConfirmation } from 'components/custom/ask-confirmation';
import { now } from 'utils/defaultDates';
import { avatarInitials } from 'utils/formattation';

interface Props {
  comments: { description: string }[];
  setValues: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          data: SendTaskDTO | undefined;
        }>
      >;
}
const Comments = ({ comments, setValues }: Props) => {
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const { openModal } = useModal();
  let shiftPressed = false;

  const editComments = useFormik<{ data: { description: string; edit: boolean }[] }>({
    enableReinitialize: true,
    initialValues: { data: comments.map((c) => ({ description: c.description, edit: false })) },
    onSubmit: (values) => {
      setValues('data.comments', values.data);
    }
  });

  const handleDeleteComment = (i: number) => {
    let newArr = [...editComments.values.data];
    newArr.splice(i, 1);
    setValues('data.comments', newArr);
  };

  return (
    <Box sx={{ mt: 2 }}>
      {editComments.values.data.map((comment, i) => (
        <Box key={`comment-${i}`} className="flex ali-center" width={'100%'} my={2} position="relative">
          <Avatar sx={{ mr: 2 }}>{avatarInitials(`${user?.firstname} ${user?.lastname}`)}</Avatar>
          <Box
            border={`1px solid ${theme.palette.divider}`}
            borderRadius={2}
            width="100%"
            p={1}
            sx={{
              '&.MuiBox-root:hover': {
                border: `1px solid ${theme.palette.primary.main}`
              },
              '.MuiBox-root:hover > & > .date': {
                display: 'none'
              },
              '.MuiBox-root:hover > & > .modify': {
                display: 'block'
              },
              '.MuiBox-root:hover > & > .separator': {
                display: 'block'
              },
              '.MuiBox-root:hover > & > .delete': {
                display: 'block'
              }
            }}
          >
            <Box className="flex just-btwn">
              <Box display="flex">
                <Typography variant="caption" sx={{ mr: 0.5, fontWeight: 700 }}>
                  {user?.firstname} {user?.lastname}
                </Typography>
                <Typography variant="caption">ha commentato:</Typography>
              </Box>
            </Box>
            <Typography className="date" variant="caption" position="absolute" right={8} top={8}>
              {dayjs(now).format(`DD/MM/YYYY HH:mm`)}
            </Typography>
            {comment.edit ? (
              <>
                <Typography
                  className="modify"
                  variant="caption"
                  sx={{ position: 'absolute', display: 'none', right: 68, top: 8, cursor: 'pointer' }}
                  onClick={() => {
                    editComments.handleSubmit();
                  }}
                >
                  Salva
                </Typography>
                <Typography
                  className="separator"
                  variant="caption"
                  sx={{ position: 'absolute', display: 'none', right: 58, top: 8, cursor: 'default' }}
                >
                  -
                </Typography>

                <Typography
                  className="delete"
                  variant="caption"
                  sx={{ position: 'absolute', display: 'none', right: 10, top: 8, cursor: 'pointer' }}
                  onClick={() => {
                    editComments.setFieldValue(`data[${i}].edit`, false);
                  }}
                >
                  Annulla
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  className="modify"
                  variant="caption"
                  sx={{ position: 'absolute', display: 'none', right: 68, top: 8, cursor: 'pointer' }}
                  onClick={() => {
                    editComments.setFieldValue(`data[${i}].edit`, true);
                  }}
                >
                  Modifica
                </Typography>
                <Typography
                  className="separator"
                  variant="caption"
                  sx={{ position: 'absolute', display: 'none', right: 58, top: 8, cursor: 'default' }}
                >
                  -
                </Typography>

                <Typography
                  className="delete"
                  variant="caption"
                  sx={{ position: 'absolute', display: 'none', right: 10, top: 8, cursor: 'pointer' }}
                  onClick={() => {
                    openModal({
                      title: 'Eliminazione commento',
                      content: (
                        <AskConfirmation
                          confirmationText="Sei sicuro di voler eliminare questo commento?"
                          onConfirmCallback={() => handleDeleteComment(i)}
                        />
                      )
                    });
                  }}
                >
                  Elimina
                </Typography>
              </>
            )}

            {comment.edit ? (
              <TextField
                size="small"
                fullWidth
                minRows={1}
                maxRows={3}
                multiline
                value={comment.description}
                onChange={(e) => {
                  editComments.setFieldValue(`data[${i}].description`, e.target.value);
                  setValues('commentToSend', e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.code === 'ShiftRight' || e.code === 'ShiftLeft') {
                    shiftPressed = true;
                  }
                  if (e.code === 'Enter' && !shiftPressed) {
                    editComments.handleSubmit();
                  }
                }}
              />
            ) : (
              <Typography variant="body2" sx={{ width: '100%', mt: 1, whiteSpace: 'pre-wrap' }}>
                {comment.description}
              </Typography>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Comments;
