import { CloseOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import SearchBar from 'components/custom/search-bar';
import { dispatch } from 'store';
import { setRightDrawerOpen } from 'store/reducers/menu';

interface Props {
  setFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const Header = ({ setFilter }: Props) => {
  return (
    <Box className="right-drawer-header">
      <Box className="header-container">
        <Box className="flex ali-center">
          <CloseOutlined
            className="clickable"
            style={{ fontSize: 22, marginRight: 14, color: '#9aa4a7' }}
            onClick={() => {
              dispatch(setRightDrawerOpen(false));
            }}
          />
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            Notifiche
          </Typography>
        </Box>
        <SearchBar width={250} handleSetFilter={setFilter} />
      </Box>
    </Box>
  );
};
