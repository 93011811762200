// material-ui
import { Box } from '@mui/material';

// project import
import MiniDrawerStyled from './styled-mini-drawer';
import { DrawerContent } from './content';

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

interface Props {
  open: boolean;
}

export const RightDrawer = ({ open }: Props) => {
  // i task e le note sono filtrati per risorsa (utenti/strutture/ordini)
  // e per soggetto (specifico utente/struttura/ordine)
  // esiste una categoria generale in cui viene restituito tutto

  // i filtri per risorsa e soggetto sono legati alla navigazione delle pagine
  // se si è nella tabella strutture e si aprono i task, si vedranno tutti i task della
  // risorsa strutture a cui si è assegnati
  // se si ci si trova in uno specifico ordine, si vedranno tutti i task di quello specifico ordine

  // al cambio di pathname, se il drawer è aperto vengono fatti dei controlli che cambiano il filtro
  // è possibile anche navigare dall'interno del drawer destro:
  //  esempio: si è nella pagina di una struttura, ma si filtra per uno specifico ordine

  return (
    <Box component="nav" sx={{ zIndex: 1500, width: 0 }} aria-label="mailbox folders">
      <MiniDrawerStyled
        ModalProps={{ keepMounted: false }}
        variant="permanent"
        open={open}
        anchor="right"
        sx={{ '.MuiDrawer-paperAnchorRight': { boxShadow: open ? '0 6px 20px 5px rgba(0,0,0,0.2)' : 'none' } }}
      >
        <DrawerContent />
      </MiniDrawerStyled>
    </Box>
  );
};
