import { Box } from '@mui/material';
import Header from './Header';
import { TaskAndSearch } from './task-and-search';
import { TasksFilters } from './tasks-filters';
import { TasksList } from './tasks-list';
import useLoader from 'hooks/useLoader';
import {
  CountedTasks,
  SectionFilterProps,
  SetPaginationProps,
  SetSectionFilterProps,
  SetShowUnreadProps,
  SetSortingProps,
  SetStateFilterProps,
  SetSubjectProps,
  ShowUnreadProps,
  SubjectProps
} from '../../types/tasks';

type Props = {
  tasks?: CountedTasks;
  sorting: string;
  setSorting: SetSortingProps;
  pageSize: number;
  setPagination: SetPaginationProps;
  setStateFilter: SetStateFilterProps;
  sectionFilter?: SectionFilterProps;
  setSectionFilter: SetSectionFilterProps;
  subject: SubjectProps;
  setSubject: SetSubjectProps;
  isFetching: boolean;
  showUnread: ShowUnreadProps;
  setShowUnread: SetShowUnreadProps;
};
export const Tasks = ({
  tasks,
  sorting,
  setSorting,
  pageSize,
  setPagination,
  setStateFilter,
  sectionFilter,
  setSectionFilter,
  subject,
  setSubject,
  isFetching,
  showUnread,
  setShowUnread
}: Props) => {
  const { isLoading } = useLoader();

  return (
    <>
      <Box className={`right-drawer-el-container ${isLoading || isFetching ? 'hide' : 'show'}`}>
        <Header
          resource={sectionFilter}
          setResource={setSectionFilter}
          subject={subject}
          setSubject={setSubject}
          setStateFilter={setStateFilter}
        />
        <Box className="standard-margin-container">
          <TaskAndSearch />
          <TasksFilters
            sorting={sorting}
            setSorting={setSorting}
            setStateFilter={setStateFilter}
            showUnread={showUnread}
            setShowUnread={setShowUnread}
          />
          <TasksList tasks={tasks} isFetching={isFetching} sorting={sorting} pageSize={pageSize} setPagination={setPagination} />
        </Box>
      </Box>
    </>
  );
};
