import { useEffect } from 'react';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'store';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { authTokenLogin } = useAuth();
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const { state } = useLocation();

  useEffect(() => {
    if (
      // l'utente non è loggato e ha il token
      !isLoggedIn &&
      (localStorage['accessToken'] || sessionStorage['accessToken']) &&
      ((localStorage['accessToken'] !== '' && localStorage['accessToken'] !== undefined) ||
        (sessionStorage['accessToken'] !== '' && sessionStorage['accessToken'] !== undefined))
    ) {
      console.log('me sloggato AUTH');
      // prova a loggarlo direttamente
      authTokenLogin();
    } else if (
      // l'utente non è loggato e non ha nessun token
      !isLoggedIn &&
      (localStorage['accessToken'] === '' || localStorage['accessToken'] === undefined) &&
      (sessionStorage['accessToken'] === '' || sessionStorage['accessToken'] === undefined)
    ) {
      // viene buttato al login nelle rotte pubbliche
      navigate('/login', { replace: true });
    } else if (
      // se è loggato, proviene dalla rotta principale di login '/', e ha il token
      isLoggedIn &&
      state?.previous !== '/' &&
      (localStorage['accessToken'] || sessionStorage['accessToken']) &&
      (localStorage['accessToken'] !== '' || sessionStorage['accessToken'] !== '')
    ) {
      // prova a loggarlo direttamente
      authTokenLogin();
    }
  }, [isLoggedIn, localStorage['accessToken'], sessionStorage['accessToken']]);

  // se l'utente è loggato e abbiamo i dati dell'utente ritorniamo le rotte protette
  return isLoggedIn && user != null ? children : <div>ciccio pasticcio</div>;
};

export default AuthGuard;
