import AsyncAutoComplete from './async-autocomplete';
import { UserDTO } from 'types/dto/user.dto';
import { BuildingDTO } from 'types/dto/building.dto';
import { OrderDTO } from 'types/dto/order.dto';
import { labelSetter } from 'utils/filtersUtils/resourceSelector';
import { Box, TextField } from '@mui/material';
import { useSelector } from 'store';
import { SetNewTaskProps, SetSubjectProps, SubjectProps } from 'layout/MainLayout/right-drawer/types/tasks';

interface Props {
  mt?: number;
  endpoint: string;
  subject?: SubjectProps;
  setSubject: SetSubjectProps;
  taskDefaultValue?: string | number;
  setNewTask?: SetNewTaskProps;
}
export const SubjectFilter = ({ endpoint, subject, setSubject, taskDefaultValue, setNewTask }: Props) => {
  const { rightDrawerOpen, activeRightItem } = useSelector((state) => state.menu);

  const disabled = !endpoint || !rightDrawerOpen || activeRightItem === 'task';
  const selectedUser = [endpoint === '/users' ? { field: 'hasAdminPermissions', value: { equals: false } } : undefined].filter((r) => r);

  return (
    <AsyncAutoComplete<UserDTO & BuildingDTO & OrderDTO>
      endpoint={endpoint}
      query={{
        filterBy: JSON.stringify(selectedUser)
      }}
      className={disabled ? (activeRightItem === 'task' ? 'hide-subject-input' : 'disabled-input') : ''}
      queryOptions={{ enabled: rightDrawerOpen && Boolean(endpoint) }}
      onFetchSuccess={(options) => {
        // se la sezione task è attiva viene selezionato l'id del soggetto (struttura, ordine o utente)
        if (taskDefaultValue && activeRightItem === 'task') {
          if (endpoint === '/orders') {
            const selectedOrder = options.find((o) => o.code === taskDefaultValue);
            if (selectedOrder) {
              setSubject({ serverData: [selectedOrder.code], label: selectedOrder.code });
            }
            return;
          }
          const selectedEl = options.find((e) => e.id === Number(taskDefaultValue));
          if (selectedEl) {
            if (endpoint === '/users') {
              setSubject({ serverData: [String(selectedEl.id)], label: `${selectedEl.firstname} ${selectedEl.lastname}` });
              return;
            }
            if (endpoint === '/buildings') {
              setSubject({ serverData: [String(selectedEl.id)], label: `${selectedEl.name}` });
            }
            return;
          }
          // se l'endpoint non è nessuno di quei tre allora soggetto vuoto
          setSubject({ serverData: [], label: '' });
        }
      }}
      getOptionLabel={(option) => (typeof option === 'string' ? option : `${labelSetter(endpoint, option)}`)}
      id={'async-subjects'}
      fullWidth
      size="small"
      value={subject?.label.length ? subject.label : 'Nessuno'}
      disabled={disabled}
      renderInput={({ InputLabelProps, ...params }) => <TextField {...params} label="Riferimento" value={subject?.label ?? 'Nessuno'} />}
      ListboxProps={{ style: { maxHeight: 270, maxWidth: 210, backgroundColor: '#ffffff', position: 'absolute', right: -200, top: 40 } }}
      // TEMPORARY FIX
      navigateRefetch={true}
      renderOption={(props, option) => (
        <li {...props} key={endpoint === '/orders' ? option.code : option.id}>
          <Box
            sx={{ paddingX: 1, paddingY: 0.3 }}
            onClick={(e) => {
              setSubject({
                serverData: [endpoint === '/orders' ? option.code : option.id.toString()],
                label: endpoint === '/orders' ? option.code : option.id.toString()
              });
            }}
          >
            {labelSetter(endpoint, option)}
          </Box>
        </li>
      )}
      onChange={(item, serverOptions, setOptions, e) => {
        const selectedEl = item as (UserDTO & BuildingDTO & OrderDTO) | null;
        if (selectedEl === null) {
          setSubject({ serverData: [], label: '' });
          setNewTask && setNewTask('data.subject', []);
          return;
        }
        const subject = {
          label: labelSetter(endpoint, selectedEl),
          id: endpoint === '/orders' ? selectedEl.code : `${selectedEl.id}`
        };

        if (endpoint === '/orders') {
          setSubject({ serverData: [selectedEl.code], label: selectedEl.code });
          setNewTask && setNewTask('data.subject', [selectedEl.code]);
          return;
        }
        if (endpoint === '/buildings') {
          setSubject({ serverData: [String(selectedEl.id)], label: selectedEl.name });
          setNewTask && setNewTask('data.subject', [selectedEl.id]);
          return;
        }
        if (endpoint === '/users') {
          setSubject({ serverData: [String(selectedEl.id)], label: `${selectedEl.firstname} ${selectedEl.lastname}` });
          setNewTask && setNewTask('data.subject', [selectedEl.id]);
          return;
        }
        setSubject({ serverData: [], label: '' });
        setNewTask && setNewTask('data.subject', []);
        setOptions(serverOptions.filter((o) => (endpoint === '/orders' ? o.code !== subject.id : o.id.toString() !== subject.id)));
      }}
    />
  );
};
