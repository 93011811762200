import { Avatar, Box, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
// import AddComment from '../Task/AddComment';
// import Comments from '../Task/Comments';
import { TabsProps } from 'types/task-tabs';
// import UploadsList from '../Task/UploadsList';
// import NewUploadsList from '../NewTask/UploadsList';
// import DragDropUpload from 'components/custom/DragDropUpload/DragDropUpload';
// import NewAddComment from '../NewTask/AddComment';
// import NewComments from '../NewTask/Comments';
// import { taskDocumentsFormat } from 'utils/fileFormats';
// import _ from 'lodash';
import { useSelector } from 'store';
import CustomTabPanel from './custom-tab-panel';
import AddTodo from './todo/add-todo';
import TodoList from './todo/todo-list';
import { Documents } from './documents';
import { UploadDTO } from 'types/dto/upload.dto';
import { Comments } from './comments';

const TaskTabs = ({ taskForm, setValues, disabled, isNewTask }: TabsProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const { rightDrawerOpen, activeRightItem } = useSelector((state) => state.menu);
  const handleChangeTab = (e: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      {rightDrawerOpen && (activeRightItem === 'new-task' || activeRightItem === 'task') ? (
        <Box className="vertical-standard-margin">
          <Tabs
            value={activeTab}
            onChange={handleChangeTab}
            variant="standard"
            scrollButtons="auto"
            aria-label="task-tabs"
            sx={{
              backgroundColor: 'transparent',
              '.MuiTab-root': { backgroundColor: 'action.hover', mr: 0.5 },
              '.Mui-selected': { backgroundColor: 'action.selected' }
            }}
          >
            <Tab
              label="checklist"
              iconPosition="end"
              icon={
                (taskForm?.todos?.length ?? 0) ? (
                  <Avatar className="task-tab-ticker" sx={{ backgroundColor: 'primary.main' }}>
                    {taskForm?.todos?.length ?? 0}
                  </Avatar>
                ) : (
                  <></>
                )
              }
            />
            <Tab
              label="allegati"
              iconPosition="end"
              icon={
                (taskForm?.uploads?.length ?? 0) ? (
                  <Avatar className="task-tab-ticker" sx={{ backgroundColor: 'primary.main' }}>
                    {taskForm?.uploads?.length ?? 0}
                  </Avatar>
                ) : (
                  <></>
                )
              }
            />
            <Tab
              label="attività"
              iconPosition="end"
              icon={
                (taskForm?.comments?.length ?? 0) ? (
                  <Avatar className="task-tab-ticker" sx={{ backgroundColor: 'primary.main' }}>
                    {taskForm?.comments?.length ?? 0}
                  </Avatar>
                ) : (
                  <></>
                )
              }
            />
          </Tabs>
          <CustomTabPanel value={activeTab} index={0}>
            <AddTodo todos={taskForm?.todos ?? []} setTodos={setValues} disabled={disabled} />
            <TodoList todos={taskForm?.todos ?? []} setTodos={setValues} disabled={disabled} />
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={1}>
            <Documents
              isNewTask={isNewTask}
              taskID={taskForm?.id}
              uploads={taskForm?.uploads as UploadDTO[]}
              lastEdit={taskForm?.lastEdit}
              setValues={setValues}
            />
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={2}>
            <Comments isNewTask={isNewTask} taskForm={taskForm!} setValues={setValues} />
          </CustomTabPanel>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default TaskTabs;
