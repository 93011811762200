import { Box, Fade, Tooltip, TooltipProps, Typography } from '@mui/material';

interface Props {
  children: React.ReactElement;
  isComplexContent?: boolean;
  content: React.ReactElement | string;
  bgColor?: string;
  color?: string;
  border?: string;
  placement?: TooltipProps['placement'];
  fontVariant?: string;
  props?: any;
  disableHoverListener?: TooltipProps['disableHoverListener'];
  zIndex?: number;
  followCursor?: boolean;
}
export const CustomTooltip = ({
  children,
  isComplexContent,
  content,
  bgColor,
  color,
  border,
  placement,
  fontVariant,
  followCursor,
  zIndex,
  ...props
}: Props) => (
  <Tooltip
    arrow
    followCursor={followCursor ?? true}
    //@ts-ignore
    title={isComplexContent ? <Box>{content}</Box> : <Typography variant={fontVariant ?? 'caption'}>{content}</Typography>}
    placement={placement ?? 'top'}
    PopperProps={{
      sx: {
        zIndex: zIndex ?? 5000
      }
    }}
    TransitionComponent={Fade}
    TransitionProps={{ timeout: 300 }}
    slotProps={{
      tooltip: {
        sx: {
          bgcolor: bgColor ?? 'primary.main',
          color: color ?? 'white',
          marginBottom: 0.5,
          border: border ?? 'none',
          '& .MuiTooltip-arrow': {
            color: bgColor ?? 'secondary.light'
          }
        }
      }
    }}
    {...props}
  >
    {children}
  </Tooltip>
);
