import { alpha, Box, Dialog, DialogContent, IconButton, styled, Typography, useTheme } from '@mui/material';
import { createContext, memo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Property } from 'csstype';

export type OpenModalProps = {
  // titolo del modal
  title?: string;
  icon?: JSX.Element;
  // se il titolo è troppo lungo, deve andare in ellipsis? (Titolo del mod...)
  titleEllipse?: boolean;
  // contenuto del modal, componente react
  content: JSX.Element;
  // colore di background, default #FFFFFF
  backgroundColor?: Property.BackgroundColor;
  // se true imposta l'altezza del modal al 100%, altrimenti è l'altezza del contenuto, default false
  fullHeight?: boolean;
  // se true il contenuto del modal non ha padding e margin, default false
  contentFullWidth?: boolean;
  size?: 'md' | 'lg' | 'xl';
};

type ModalContextType = {
  openModal: (props: OpenModalProps) => void;
  closeModal: () => void;
};

const BuyplusDialog = styled(Dialog)(({ theme, fullScreen }) => ({
  '.MuiPaper-root': {
    overflow: 'hidden',
    ...(!fullScreen && { maxWidth: 750 }),
    maxHeight: { xs: '100%', md: '90%' },
    ...(!fullScreen && { maxWidth: { md: 800, xl: 900 } }),
    borderRadius: 0,
    borderTop: `3px solid ${theme.palette.secondary.main}`
  },
  '&.MuiDialog-root': {
    zIndex: 2000
  }
}));

export const ModalContext = createContext<ModalContextType | null>(null);

export const ModalProvider = memo(({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<string>();
  const [icon, setIcon] = useState<JSX.Element>();
  const [content, setContent] = useState<JSX.Element>();
  const [fullHeight, setFullHeight] = useState(false);
  const [contentFullWidth, setContentFullWidth] = useState(false);
  const [size, setSize] = useState<'md' | 'lg' | 'xl' | undefined>();

  const closeModal: ModalContextType['closeModal'] = () => {
    setOpen(false);
  };

  // openModal è la funzione che chiamiamo all'interno dei component per aprire un modal per esempio attraverso un evento
  // le uniche opzioni obbligatorie sono "title" e "content", le altre opzionali hanno un default
  const openModal: ModalContextType['openModal'] = ({ title, content, fullHeight, contentFullWidth, icon, size }) => {
    setTitle(title ?? undefined);
    setIcon(icon);
    setContent(content);
    setFullHeight(fullHeight ?? false);
    setContentFullWidth(contentFullWidth ?? false);
    setOpen(true);
    setSize(size);
  };

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal
      }}
    >
      {children}
      <BuyplusDialog
        open={open}
        fullWidth
        onClose={closeModal}
        sx={{
          '.MuiPaper-root': {
            height: fullHeight ? '100%' : 'auto',
            maxHeight: '95%',
            backgroundColor: '#ffffff',
            // diverse dimensioni del modal, modificabili/espandibili in base alle esigenze
            maxWidth: size === 'xl' ? 1200 : size === 'lg' ? 1000 : 800
          }
        }}
      >
        <Box
          sx={{
            // se il titolo fosse === '', l'altezza sarebbe di 30px
            height: title ? '65px' : '30px',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.grey['100']}`
          }}
          width={'100%'}
        >
          <Box className="flex ali-center just-btwn" sx={{ width: '100%' }} px={2}>
            {/*
                inserisce l'icona se esistente
            */}
            {icon && (
              <Box className={'iconBox'} sx={{ backgroundColor: alpha(theme.palette.primary.dark, 0.2) }}>
                {icon}
              </Box>
            )}

            {/* componente del titolo */}
            <Typography
              sx={{
                flexGrow: 1,
                textAlign: 'center',
                fontSize: '24px',
                fontWeight: 600,
                color: theme.palette.primary.main,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '100%',
                marginLeft: 1
              }}
            >
              {title}
            </Typography>

            <IconButton data-cy={'close_modal'} onClick={closeModal}>
              <CloseIcon style={{ color: theme.palette.grey['500'] }} />
            </IconButton>
          </Box>
        </Box>
        <DialogContent
          sx={{
            flexGrow: 1,
            height: 'calc(100% - 70px)',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            padding: 0,

            '&::-webkit-scrollbar': { width: 0 },
            ...(!contentFullWidth
              ? {
                  width: '100%',
                  marginX: 'auto',
                  maxWidth: size === 'xl' ? 1200 : size === 'lg' ? 1000 : 800,
                  paddingY: '12px!important'
                }
              : {})
          }}
        >
          {content}
        </DialogContent>
      </BuyplusDialog>
    </ModalContext.Provider>
  );
});
