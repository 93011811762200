import { FormattedMessage } from 'react-intl';

// assets
import PartnersLogo from 'assets/images/icons/navigation/Partners';

// type
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const partners: NavItemType = {
  id: 'partners',
  title: '',
  type: 'group',
  children: [
    {
      id: 'partners',
      //@ts-ignore
      title: <FormattedMessage id="partners" />,
      type: 'item',
      icon: PartnersLogo,
      permissions: [{ resource: 'walletPartner', operations: ['read'] }],
      url: '/partners'
    }
  ]
};

export default partners;
