import { Typography } from '@mui/material';
import { FadeBox } from '../Animation/fade-box';

interface Props {
  label: string;
}
const Label = ({ label }: Props) => (
  <FadeBox>
    <Typography variant="h5">{label}</Typography>
  </FadeBox>
);

export default Label;
