import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';
import { dispatch, useSelector } from 'store';
import { setInizialized } from '../../store/reducers/auth';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const { authTokenLogin } = useAuth();
  const { isLoggedIn, isInitialized } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      // se l'utente non è loggato e c'è il token (nel localStorage | sessionStorage)
      !isLoggedIn &&
      (localStorage['accessToken'] || sessionStorage['accessToken']) &&
      ((localStorage['accessToken'] !== '' && localStorage['accessToken'] !== undefined) ||
        (sessionStorage['accessToken'] !== '' && sessionStorage['accessToken'] !== undefined))
    ) {
      // allora prova a fare il login con il token (che ricordo, se invalido proverà a refresharli)
      authTokenLogin();
    } else if (
      // se altrimenti è loggato e ha il token
      isLoggedIn &&
      (localStorage['accessToken'] || sessionStorage['accessToken']) &&
      ((localStorage['accessToken'] !== '' && localStorage['accessToken'] !== undefined) ||
        (sessionStorage['accessToken'] !== '' && sessionStorage['accessToken'] !== undefined))
    ) {
      // viene redirectato sulla dashboard
      navigate('/dashboard', { replace: true });
    } else {
      // l'utente deve avere i token, se non li ha, probabilmente l'app è da inizializzare
      dispatch(setInizialized());
    }
    // questo succede se l'utente si logga/slogga, e quando cambia pagina
  }, [isLoggedIn, navigate]);

  // se l'app è inizializzata e l'utente non è loggato, allora si ritornano le rotte pubbliche
  // altrimenti nada
  return isInitialized && !isLoggedIn ? children : <div>ciccio pasticcio</div>;
};

export default GuestGuard;
