import { Box, useTheme } from '@mui/material';
import { useSelector } from 'store';

interface Props {
  children: any;
}
export const CustomToolbar = ({ children }: Props) => {
  const theme = useTheme();
  const { drawerOpen } = useSelector((state) => state.menu);
  return (
    <Box
      position="fixed"
      color="inherit"
      sx={{
        display: 'flex',
        alignItems: 'center',
        zIndex: theme.zIndex.drawer - 1,
        height: '74px',
        width: drawerOpen ? 'calc(100% - 260px)' : 'calc(100% - 60px)',
        paddingX: 2,
        marginLeft: drawerOpen ? '260px' : '60px',
        borderTop: process.env.NODE_ENV === 'production' ? '4px solid #F0FF32' : '4px solid #C8D600',
        backgroundColor: process.env.NODE_ENV === 'production' ? 'background.paper' : 'secondary.light',
        transition: `all ${drawerOpen ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp} 0ms!important`
      }}
    >
      {children}
    </Box>
  );
};

// position: 'fixed',
// color: 'inherit',
// elevation: 0,
// sx: {
//   backgroundColor: 'Background.paper',
//   colori diversi in base all'environment per distinguerli graficamente
//   borderTop: process.env.NODE_ENV === 'production' ? '4px solid #F0FF32' : `4px solid #C8D600`,
//   borderBottom: `1px solid ${theme.palette.divider}`,
//   zIndex: 1200,
//   la width è calcolata in base alle dimensioni del drawer sinistro
//   calc(100% - 260px) è il drawer aperto
//   calc(100% - 60px) è il drawer chiuso
//   width: isHorizontal ? '100%' : open ? 'calc(100% - 260px)' : { xs: '100%', md: 'calc(100% - 60px)', lg: 'calc(100% - 60px)' }
// }

// const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })<Props>(({ theme, open }) => ({
// 	zIndex: theme.zIndex.drawer + 1,
// 	transition: theme.transitions.create(['width', 'margin'], {
// 	  easing: theme.transitions.easing.sharp,
// 	  duration: theme.transitions.duration.leavingScreen
// 	}),
// 	...(!open && {
// 	  width: `calc(100% - ${theme.spacing(7.5)})`
// 	}),
// 	...(open && {
// 	  marginLeft: DRAWER_WIDTH,
// 	  width: `calc(100% - ${DRAWER_WIDTH}px)`,
// 	  transition: theme.transitions.create(['width', 'margin'], {
// 		easing: theme.transitions.easing.sharp,
// 		duration: theme.transitions.duration.enteringScreen
// 	  })
// 	})
//   }));
