import { Box, Skeleton, useTheme } from '@mui/material';

interface Props {
  pageSize: number;
}
const NotesLoading = ({ pageSize }: Props) => {
  const theme = useTheme();
  const rep = () => {
    const result = [];
    for (let i = 0; i < pageSize; i++) {
      result.push(i);
    }
    return result;
  };

  return (
    <Box sx={{ mt: 2, border: `1px solid ${theme.palette.divider}`, borderRadius: 4 }}>
      {rep().map((id) => (
        <Box key={id} className="flex just-btwn" sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
          <Box className="flex ali-center">
            <Skeleton animation="wave" variant="circular" sx={{ margin: 1.5, width: 40, height: 40 }} />
            <Box sx={{ ml: 1 }}>
              <Skeleton animation="wave" sx={{ mr: 0.5, width: 100 }} />
              <Box display="flex">
                <Skeleton animation="wave" sx={{ mr: 0.5, width: 50 }} />
                <Skeleton animation="wave" sx={{ mr: 0.5, width: 50 }} />
              </Box>
            </Box>
          </Box>
          <Box className="flex ali-center">
            <Skeleton animation="wave" sx={{ mr: 2, width: 100 }} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default NotesLoading;
