import axios, { AxiosError, AxiosInstance } from 'axios';
import useAuth from '../hooks/useAuth';
import { useError } from '../hooks/useError';
import React, { createContext, memo, useEffect } from 'react';
import { ErrorDTO } from 'types/dto/error.dto';
// import { useErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'store';

//Creo l'istanza di axios settando l'url base
const apiVersion: string = process.env.REACT_APP_API_VERSION;
const serverUrl: string = process.env.REACT_APP_SERVER_URL;
const axiosInstance = axios.create({
  baseURL: serverUrl + 'server/' + apiVersion,
  headers: {
    post: {
      'Content-Type': 'application/json'
    }
  }
});

const AxiosInstanceContext = createContext<{ axiosInstance: AxiosInstance } | null>(null);

export const AxiosInstanceProvider = memo(({ children }: { children: React.ReactElement }) => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { logout, refreshToken } = useAuth();
  const { handleError } = useError();
  // da implementare il boundary
  // const { showBoundary } = useErrorBoundary();

  // aggiunta degli interceptor per catturare tutte le risposte dell'istanza, per controllare gli errori
  useEffect(() => {
    if (!isLoggedIn) return;
    axiosInstance.interceptors.response.clear();
    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error: AxiosError<ErrorDTO>) => {
        if (!axios.isAxiosError(error)) return;
        const originalRequest = error.config!;
        // Se l'errore è "invalid_token" si rimuove la scadenza dal localStorage e si tenta il refresh del token
        // Se la risposta è positiva si salvano i nuovi access e refresh token, e la chiamata di prima andata in errore
        if (error.response?.data?.message === 'invalid_token' && error.response?.data?.statusCode === 403) {
          localStorage.removeItem('accessExpireAt');
          await refreshToken();
          originalRequest!.headers['Authorization'] =
            'Bearer ' + (localStorage['stayActive'] === 'true' ? localStorage['accessToken'] : sessionStorage['accessToken']);
          return axios(originalRequest);
        } else if (error.response?.data?.message === 'Unauthorized' || error.response?.data.statusCode === 502) {
          handleError(error);
          await logout();
        } else if (error.code?.includes('ERR_NETWORK')) {
          if (originalRequest.url === '/client-logs') return;
          console.log('sync in corso', error);
          // showBoundary(
          //   new Error("Ops c'è un problema di rete, riprova fra qualche minuto.", {
          //     cause: 'server_sync'
          //   })
          // );
        } else {
          if (originalRequest.url === '/client-logs') return;
          handleError(error);
          return Promise.reject(error);
        }
      }
    );
  }, [localStorage['stayActive'], localStorage['refreshToken'], sessionStorage['refreshToken'], isLoggedIn]);

  return (
    <AxiosInstanceContext.Provider
      value={{
        axiosInstance
      }}
    >
      {children}
    </AxiosInstanceContext.Provider>
  );
});

export default AxiosInstanceContext;
