import { Box, Skeleton } from '@mui/material';

interface Props {
  pageSize: number;
}
const TasksLoading = ({ pageSize }: Props) => {
  const rep = () => {
    const result = [];
    for (let i = 0; i < pageSize; i++) {
      result.push(i);
    }
    return result;
  };

  return (
    <Box borderRadius={4}>
      {rep().map((id, i) => (
        <Box key={id} className={`flex just-btwn ${i + 1 < rep().length ? 'borderBottom' : ''}`}>
          <Box className="flex ali-center">
            <Skeleton animation="wave" variant="circular" sx={{ margin: 0.65, width: 40, height: 40 }} />
            <Box sx={{ ml: 1 }}>
              <Skeleton animation="wave" sx={{ mr: 0.5, width: 100 }} />
              <Box display="flex">
                <Skeleton animation="wave" sx={{ mr: 0.5, width: 50 }} />
                <Skeleton animation="wave" sx={{ mr: 0.5, width: 50 }} />
              </Box>
            </Box>
          </Box>
          <Box className="flex ali-center">
            <Skeleton animation="wave" sx={{ mr: 4, width: 100, height: 40, borderRadius: 4 }} />
            <Skeleton animation="wave" sx={{ mr: 2, width: 120 }} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default TasksLoading;
