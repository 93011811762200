// material-ui
import { Box } from '@mui/material';

// project import
import DrawerHeader from './header';
import DrawerContent from './content';
import DrawerStyled from './styled-drawer';

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

interface Props {
  open: boolean;
  window?: () => Window;
  handleDrawerToggle?: () => void;
}
// componente ereditato
// parzialmente modificato
// drawer sinistro con le voci di menù
const MainDrawer = ({ open }: Props) => {
  // responsive drawer container

  return (
    <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1200 }} aria-label="mailbox folders">
      <DrawerStyled variant="permanent" open={open}>
        <DrawerHeader open={open} />
        <DrawerContent />
      </DrawerStyled>
    </Box>
  );
};

export default MainDrawer;
