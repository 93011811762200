import { BuildingUser } from 'types/dto/building.dto';
import { NotificationType, NotifyTag } from 'types/dto/notification.dto';
import { OrderState } from 'types/dto/status.dto';

export const isEven = (number: number) => {
  return number % 2 === 0;
};

export const isCollaborator = (users: BuildingUser[]) => {
  return users.filter((obj) => obj.server === true);
};

export const hasRevenueManagement = (int: boolean | null, ext: string | null) => {
  if (int !== null) {
    return 'Interno';
  } else if (ext !== null) {
    return ext;
  } else return 'Non presente';
};

export const checkRiskString = (val: number | undefined) => {
  if (val === undefined) {
    return 'NON DISPONIBILE';
  } else if (val <= 20) {
    return 'RISCHIO MOLTO ALTO';
  } else if (val <= 29) {
    return 'RISCHIO ALTO';
  } else if (val <= 50) {
    return 'RISCHIO MODERATO';
  } else if (val <= 70) {
    return 'RISCHIO BASSO';
  } else if (val <= 100) {
    return 'RISCHIO MOLTO BASSO';
  }
};

export const checkRiskColor = (val: number | null | undefined) => {
  if (!val) {
    return '#999999';
  } else if (val <= 20) {
    return '#f54021';
  } else if (val <= 29) {
    return '#ff8f27';
  } else if (val <= 50) {
    return '#fabc45';
  } else if (val <= 70) {
    return '#5dbd9a';
  } else if (val <= 100) {
    return '#348d6f';
  }
};

export const trackingDurationColor = (interval?: number): string => {
  const value = Number(interval);
  let result = '';
  !value
    ? (result = '')
    : value < 60
      ? (result = 'success.main')
      : value < 1440
        ? (result = '#ffca29')
        : value < 10080
          ? (result = 'warning.main')
          : value < 40320
            ? (result = 'error.main')
            : (result = 'common.black');
  return result;
};

interface GenerateColorsProps {
  data: { x: string; y: number }[];
  colors: { lt: string; eq: string; gt: string };
}
export const generateColors = ({
  data,
  colors
}: GenerateColorsProps): {
  offset: number;
  color: string;
  opacity: number;
}[] => {
  return data.map((d, i) => {
    let color = d.y > data[0].y ? colors.gt : d.y < data[0].y ? colors.lt : colors.eq;
    return {
      offset: (i / data.length) * 110,
      color,
      opacity: 1
    };
  });
};

export const insertMonth = (i: number) => {
  switch (i) {
    case 0:
      return 'Gennaio';
    case 1:
      return 'Febbraio';
    case 2:
      return 'Marzo';
    case 3:
      return 'Aprile';
    case 4:
      return 'Maggio';
    case 5:
      return 'Giugno';
    case 6:
      return 'Luglio';
    case 7:
      return 'Agosto';
    case 8:
      return 'Settembre';
    case 9:
      return 'Ottobre';
    case 10:
      return 'Novembre';
    default:
      return 'Dicembre';
  }
};

export const monthSelector = (month: string) => {
  switch (month) {
    case 'january':
      return 'Gennaio';
    case 'february':
      return 'Febbraio';
    case 'march':
      return 'Marzo';
    case 'april':
      return 'Aprile';
    case 'may':
      return 'Maggio';
    case 'june':
      return 'Giugno';
    case 'july':
      return 'Luglio';
    case 'august':
      return 'Agosto';
    case 'september':
      return 'Settembre';
    case 'october':
      return 'Ottobre';
    case 'november':
      return 'Novembre';
    default:
      return 'Dicembre';
  }
};

export const notificationObjectWithArticle = (resource: string): string => {
  switch (resource) {
    case 'building':
      return 'la struttura';
    case 'order':
      return "l'ordine";
    case 'user':
      return "l'utente";
    case 'task':
      return 'il task';
    case 'note':
      return 'la nota';
    default:
      return 'la richiesta automatica di documentazione';
  }
};
export const notificationObject = (resource: string): string => {
  switch (resource) {
    case 'building':
      return 'la struttura';
    case 'order':
      return "l'ordine";
    case 'user':
      return "l'utente";
    case 'task':
      return 'il task';
    case 'note':
      return 'la nota';
    default:
      return 'la richiesta automatica di documentazione';
  }
};

export const notificationAction = (operation: string) => {
  switch (operation) {
    case 'create':
      return 'creato';
    case 'update':
      return 'modificato';
    default:
      return 'eliminato';
  }
};

export const createNotification = (data: NotificationType) => {
  if (`${data.user.firstname} ${data.user.lastname}` === data.subjectName) {
    return `Aggiornamento ${notificationObject(data.resource ?? '')} ${data.resource === 'order' ? data.subject : data.subjectName}`;
  }
  if (data.tag === NotifyTag.Contact)
    return `${data.user.firstname ?? ''} ${data.user.lastname ?? ''} ha richiesto la modifica dei suoi dati`;
  else
    return `${data.user.firstname ?? ''} ${data.user.lastname ?? ''} ha ${notificationAction(
      data.operation ?? ''
    )} ${notificationObjectWithArticle(data.resource ?? '')} ${data.resource === 'order' ? data.subject : data.subjectName}`;
};

export const translateOrderStatus = (status: OrderState, isProduct: boolean) => {
  if (isProduct) {
    if (status === OrderState.InProgress) {
      return 'Merce spedita';
    }
    if (status === OrderState.Completed) {
      return 'Merce consegnata';
    }
  }
  if (!isProduct) {
    if (status === OrderState.InProgress) {
      return 'Servizio in corso';
    }
    if (status === OrderState.Completed) {
      return 'Servizio completato';
    }
  }
  switch (status) {
    case OrderState.Filled:
      return 'Da verificare';
    case OrderState.Refused:
      return 'Rifiutato';
    case OrderState.UserApproved:
      return 'Richiesta approvata';
    case OrderState.AdminProcessing:
      return 'Elaborazione pro.';
    case OrderState.AdminPublished:
      return 'Attesa firma mod.';
    case OrderState.AdminRefused:
      return 'Modulo rifiutato';
    case OrderState.Approved:
      return 'Modulo accettato';
    case OrderState.IssuedInvoice:
      return 'Fattura emessa';
    case OrderState.DepositPaid:
      return 'Acconto pagato';
    case OrderState.Setup:
      return 'Collegato al CM';
    default:
      return 'Bozza';
  }
};

export const checkOrderAdvancement = (state: OrderState) => {
  if (state === OrderState.AdminProcessing) {
    return 1;
  }
  if (state === OrderState.AdminPublished) {
    return 2;
  }
  if (state === OrderState.Approved) {
    return 3;
  }
  if (state === OrderState.IssuedInvoice) {
    return 4;
  }
  if (state === OrderState.DepositPaid) {
    return 5;
  }
  if (state === OrderState.Setup) {
    return 6;
  }
  if (state === OrderState.InProgress) {
    return 7;
  }
  if (state === OrderState.Completed) {
    return 8;
  }
  return 0;
};
