import { CloseOutlined } from '@mui/icons-material';
import { Box, FormControl, Typography } from '@mui/material';
import { endpointReturn } from 'utils/selectEndpoint/endpointSetter';
import { SectionSelector } from '../../shared-components/section-selector';
import { SubjectFilter } from 'components/custom/async-selectors/subject-filter';
import { dispatch } from 'store';
import { setRightDrawerOpen } from 'store/reducers/menu';
import { SectionFilterProps, SetSectionFilterProps, SetStateFilterProps, SetSubjectProps, SubjectProps } from '../../types/tasks';

interface Props {
  resource?: SectionFilterProps;
  setResource: SetSectionFilterProps;
  subject: SubjectProps;
  setSubject: SetSubjectProps;
  setStateFilter: SetStateFilterProps;
}
const Header = ({ resource, setResource, subject, setSubject, setStateFilter }: Props) => {
  const endpoint = endpointReturn(resource);

  return (
    <Box className="right-drawer-header">
      <Box className="header-container">
        <Box className="flex ali-center">
          <CloseOutlined
            className="clickable"
            style={{ fontSize: 22, marginRight: 14, color: '#9aa4a7' }}
            onClick={() => {
              dispatch(setRightDrawerOpen(false));
            }}
          />
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            Task
          </Typography>
        </Box>
        <Box className="flex ali-center">
          <SectionSelector section={resource} setSection={setResource} setStateFilter={setStateFilter} setSubject={setSubject} />
          <FormControl
            sx={{
              width: 230
            }}
          >
            <SubjectFilter key="tasks-subject" mt={0} endpoint={endpoint} subject={subject} setSubject={setSubject} />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
