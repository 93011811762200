import { keyframes, styled } from '@mui/material/styles';

const blink = keyframes`
  0%, 100% { opacity: 0.65; }
  50% { opacity: 1; }
`;

export const BlinkedBox = styled('div')({
  animation: `${blink} 1s linear infinite`
});
