import { Box, Button, Chip, Typography } from '@mui/material';
import { BlinkedBox } from 'components/custom/Animation/blink-box';
import { dispatch, useSelector } from 'store';
import useModal from 'hooks/useModal';
import { SendTaskDTO } from 'types/dto/task.dto';
import { AskConfirmation } from 'components/custom/ask-confirmation';
import { useEndpoint } from 'hooks/useEndpoint';
import { setNewTaskTitle } from 'store/reducers/helpers';
import { setActiveRightItem } from 'store/reducers/menu';
import { useRefetchQueries } from 'hooks/useRefetchQueries';

interface Props {
  taskForm: SendTaskDTO;
  disabled: boolean;
  isNewTask: boolean;
  submit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

const Footer = ({ disabled, taskForm, isNewTask, submit }: Props) => {
  const { openModal } = useModal();
  const { refetchQueries, queryPresets } = useRefetchQueries();

  const { user } = useSelector((state) => state.auth);
  const helpers = useSelector((state) => state.helpers);

  const deleteTask = useEndpoint<undefined, 'delete'>({
    method: 'delete',
    endpoint: `/tasks/${taskForm.id}`,
    mutationKey: `delete-task-${taskForm.id}`,
    options: {
      onSuccess: () => {
        refetchQueries(queryPresets.tasks);
        dispatch(setActiveRightItem('tasks'));
        dispatch(setNewTaskTitle(''));
      }
    }
  });
  return (
    <Box className="task-footer">
      <Typography
        variant="body2"
        color="error.main"
        sx={{ ml: 2, cursor: 'pointer', '&.MuiTypography-root:hover': { color: 'error.light' } }}
        onClick={() =>
          openModal({
            title: `Conferma eliminazione task`,
            content: (
              <AskConfirmation
                confirmationText={`Vuoi davvero eliminare il task "${taskForm.title}"?`}
                onConfirmCallback={() => deleteTask.mutate(undefined)}
              />
            )
          })
        }
      >
        {isNewTask ? '' : 'Elimina task'}
      </Typography>
      {helpers.isEditingTask && helpers.isEditingTask.id === taskForm.id && helpers.isEditingTask.userId !== user?.id && (
        <Box>
          <BlinkedBox>
            <Chip label="Qualcuno sta modificando il task..." color="secondary" />
          </BlinkedBox>
        </Box>
      )}
      <Box>
        <Button variant="contained" disabled={!disabled} onClick={() => submit()}>
          {isNewTask ? 'crea' : disabled ? 'da salvare' : 'salvato'}
        </Button>
      </Box>
    </Box>
  );
};

export default Footer;
