import { FormattedMessage } from 'react-intl';

// assets
import ContractsLogo from 'assets/images/icons/navigation/Contracts';

// type
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const contracts: NavItemType = {
  id: 'contracts',
  title: '',
  type: 'group',
  children: [
    {
      id: 'contracts',
      //@ts-ignore
      title: <FormattedMessage id="contracts" />,
      type: 'item',
      icon: ContractsLogo,
      permissions: [{ resource: null, operations: ['read', 'create', 'update', 'delete'] }],
      url: '/platform-contracts'
    }
  ]
};

export default contracts;
