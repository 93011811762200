import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import ArrowDown from 'assets/images/icons/navigation/ArrowDown';
import ArrowUp from 'assets/images/icons/navigation/ArrowUp';
import { SetSortingProps } from 'layout/MainLayout/right-drawer/types/tasks';

interface Props {
  sorting: string;
  setSorting: SetSortingProps;
  filters: ('expire' | 'update' | 'created')[];
}
const OrderSelect = ({ sorting, setSorting, filters }: Props) => {
  return (
    <FormControl size="small" sx={{ minWidth: 130, maxWidth: 200, mr: -1 }}>
      <Select
        sx={{ pr: 0 }}
        inputProps={{ IconComponent: () => null }}
        value={sorting}
        onChange={(e) => setSorting(e.target.value)}
        variant="outlined"
        MenuProps={{
          sx: { zIndex: 1500 },
          PaperProps: {
            sx: {
              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
              borderRadius: '6px',
              pr: 0
            }
          }
        }}
      >
        {filters.includes('expire') && (
          <MenuItem value={'{ "field": "expireAt", "sort": "desc" }'}>
            <Box className="flex ali-center" sx={{ mt: 0.2, textAlign: 'right' }}>
              <Typography variant="body2" className="textSecondary" sx={{ mr: 5 }}>
                Data di scadenza
              </Typography>
              <ArrowUp sx={{ width: 12 }} />
            </Box>
          </MenuItem>
        )}
        {filters.includes('expire') && (
          <MenuItem value={'{ "field": "expireAt", "sort": "asc" }'}>
            <Box className="flex ali-center" sx={{ mt: 0.2, textAlign: 'right' }}>
              <Typography variant="body2" className="textSecondary" sx={{ mr: 5 }}>
                Data di scadenza
              </Typography>
              <ArrowDown sx={{ width: 12 }} />
            </Box>
          </MenuItem>
        )}
        {filters.includes('created') && (
          <MenuItem value={'{ "field": "createdAt", "sort": "desc" }'}>
            <Box className="flex ali-center" sx={{ mt: 0.2, textAlign: 'right' }}>
              <Typography variant="body2" className="textSecondary" sx={{ mr: 5 }}>
                Data di creazione
              </Typography>
              <ArrowUp sx={{ width: 12 }} />
            </Box>
          </MenuItem>
        )}
        {filters.includes('created') && (
          <MenuItem value={'{ "field": "createdAt", "sort": "asc" }'}>
            <Box className="flex ali-center" sx={{ mt: 0.2, textAlign: 'right' }}>
              <Typography variant="body2" className="textSecondary" sx={{ mr: 5 }}>
                Data di creazione
              </Typography>
              <ArrowDown sx={{ width: 12 }} />
            </Box>
          </MenuItem>
        )}
        {filters.includes('update') && (
          <MenuItem value={'{ "field": "lastEdit", "sort": "desc" }'}>
            <Box className="flex ali-center" sx={{ mt: 0.2, textAlign: 'right' }}>
              <Typography variant="body2" className="textSecondary" sx={{ mr: 0.9 }}>
                Data di aggiornamento
              </Typography>
              <ArrowUp sx={{ width: 12 }} />
            </Box>
          </MenuItem>
        )}
        {filters.includes('update') && (
          <MenuItem value={'{ "field": "lastEdit", "sort": "asc" }'}>
            <Box className="flex ali-center" sx={{ mt: 0.2, textAlign: 'right' }}>
              <Typography variant="body2" className="textSecondary" sx={{ mr: 0.9 }}>
                Data di aggiornamento
              </Typography>
              <ArrowDown sx={{ width: 12 }} />
            </Box>
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default OrderSelect;
