import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const BuildingLogo = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx ?? {}} fontSize={props.fontSize ?? 'medium'}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.29 24.04">
      <g>
        <path fill="#094754" d="m17.29,24.04H0V4.19c0-1.03.89-1.87,1.98-1.87h13.32c1.1,0,1.98.84,1.98,1.87v19.85h0Z" />
        <rect fill="#f0ff32" x="5.94" y="21.3" width="5.4" height="2.74" />
        <path fill="#f0ff32" d="m11.34,21.61c0-1.49-1.21-2.7-2.7-2.7s-2.7,1.21-2.7,2.7" />
        <path fill="#91dcf9" d="m4.2,0h8.87c.42,0,.75.34.75.75v1.55H3.46V.74c0-.41.33-.74.74-.74h0Z" />
        <rect fill="#1ed367" x="3.46" y="2.3" width="10.37" height="2.3" />
        <rect fill="#91dcf9" x="10.68" y="7.42" width="4.02" height="2.3" />
        <rect fill="#91dcf9" x="2.59" y="7.42" width="4.02" height="2.3" />
        <rect fill="#91dcf9" x="10.68" y="11.12" width="4.02" height="2.3" />
        <rect fill="#91dcf9" x="2.59" y="11.12" width="4.02" height="2.3" />
        <rect fill="#91dcf9" x="10.68" y="14.89" width="4.02" height="2.3" />
        <rect fill="#91dcf9" x="2.59" y="14.89" width="4.02" height="2.3" />
      </g>
    </svg>
  </SvgIcon>
);

export default BuildingLogo;
