import { Box, Typography, useTheme } from '@mui/material';
import { setShowStatusMenuProps, SetStateFilterProps } from 'layout/MainLayout/right-drawer/types/tasks';

interface Props {
  statusOpen: boolean;
  setStatusOpen: setShowStatusMenuProps;
  setStateFilter: SetStateFilterProps;
}
const StatusFilter = ({ statusOpen, setStatusOpen, setStateFilter }: Props) => {
  const theme = useTheme();

  return (
    <Box
      display={statusOpen ? 'block' : 'none'}
      flexDirection="column"
      width={140}
      position="absolute"
      zIndex={10}
      pb={1}
      sx={{
        backgroundColor: 'common.white',
        borderRadius: '10px',
        boxShadow: theme.customShadows.primaryButton
      }}
      right={-30}
    >
      <Box width="90%" mx="auto" sx={{ backgroundColor: 'common.white' }}>
        <Box sx={{ mt: 0.5 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: 1,
              borderRadius: 20,
              border: `1px solid ${theme.palette.action.disabled}`,
              color: 'primary.main',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: 'action.hover'
              }
            }}
            onClick={() => {
              setStateFilter(undefined);
              setStatusOpen(false);
            }}
          >
            <Typography variant="body2">Tutti</Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 0.5 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: 1,
              borderRadius: 20,
              color: 'primary.main',
              backgroundColor: 'action.selected',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: 'action.hover'
              }
            }}
            onClick={() => {
              setStateFilter({ field: ['state', 'key'], value: { equals: 'created' } });
              setStatusOpen(false);
            }}
          >
            <Typography variant="body2">Bozza</Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 0.5 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: 1,
              borderRadius: 20,
              color: 'common.white',
              backgroundColor: 'primary.main',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: 'primary.dark'
              }
            }}
            onClick={() => {
              setStateFilter({ field: ['state', 'key'], value: { equals: 'pending' } });
              setStatusOpen(false);
            }}
          >
            <Typography variant="body2">In lavorazione</Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 0.5 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: 1,
              borderRadius: 20,
              color: 'primary.main',
              backgroundColor: 'warning.main',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: 'warning.dark'
              }
            }}
            onClick={() => {
              setStateFilter({ field: ['state', 'key'], value: { equals: 'paused' } });
              setStatusOpen(false);
            }}
          >
            <Typography variant="body2">In pausa</Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 0.5 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: 1,
              borderRadius: 20,
              color: 'primary.main',
              backgroundColor: 'warning.light',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: 'warning.main'
              }
            }}
            onClick={() => {
              setStateFilter({ field: ['state', 'key'], value: { equals: 'expired' } });
              setStatusOpen(false);
            }}
          >
            <Typography variant="body2">Scaduto</Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 0.5 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: 1,
              borderRadius: 20,
              color: 'primary.main',
              backgroundColor: 'error.light',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: 'error.main'
              }
            }}
            onClick={() => {
              setStateFilter({ field: ['state', 'key'], value: { equals: 'deleted' } });
              setStatusOpen(false);
            }}
          >
            <Typography variant="body2">Annullato</Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 0.5 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              py: 1,
              borderRadius: 20,
              color: 'primary.main',
              backgroundColor: 'success.main',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: 'success.dark'
              }
            }}
            onClick={() => {
              setStateFilter({ field: ['state', 'key'], value: { equals: 'completed' } });
              setStatusOpen(false);
            }}
          >
            <Typography variant="body2">Completato</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StatusFilter;
