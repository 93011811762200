import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/material';
import NoElement from 'assets/images/illustrations/noElement';

const NoTasks = () => {
  const theme = useTheme();

  return (
    <Box className="flex ali-center dir-col vertical-standard-margin" padding={6} sx={{ backgroundColor: `${theme.palette.action.hover}` }}>
      <NoElement width={300} />
      <Typography variant="h6" sx={{ mt: 4 }}>
        Nessun Task
      </Typography>
    </Box>
  );
};

export default NoTasks;
