// material-ui
import { ButtonBase, SxProps } from '@mui/material';

// project import
import Logo from './LogoMain';
import LogoIcon from './LogoIcon';

// ==============================|| MAIN LOGO ||============================== //

interface Props {
  isIcon?: boolean;
  sx?: SxProps;
}

const LogoSection = ({ isIcon, sx }: Props) => (
  <ButtonBase disableRipple sx={sx}>
    {isIcon ? <LogoIcon /> : <Logo />}
  </ButtonBase>
);

export default LogoSection;
