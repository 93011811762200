// project import
import analytics from './analytics';
// types
import { NavItemType } from 'types/menu';
import users from './users';
import collaborators from './collaborators';
import settings from './settings';
import referralCodes from './referral-codes';
import faq from './faq';
import contracts from './platform-contracts';
import partners from './partners';
import log from './log';
import support from './support';
import autopilot from './autopilot';
// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  // la struttura di questa sezione è parzialmente ereditata
  // nulla vieta un cambio radicale di struttura se porta a nuove funzionalità
  // che al momento non servivano

  // i commenti li ho inseriti solo in analytics
  // che in realtà è un raggruppamento di più voci che non corrispondono
  // esattamente alla categoria "analytics", ma viene sfruttato solo perchè
  // l'array è ordinato manualmente inserendo le voci hardcodate
  items: [analytics, users, collaborators, partners, contracts, referralCodes, settings, log, faq, support, autopilot]
};

export default menuItems;
