import { Avatar, Badge, Chip, useTheme } from '@mui/material';
import { useEndpoint } from 'hooks/useEndpoint';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setActiveRightItem, setRightDrawerOpen } from 'store/reducers/menu';

const Task = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { tasksCount } = useSelector((state) => state.helpers);
  const { activeRightItem, rightDrawerOpen } = useSelector((state) => state.menu);

  /*  funzione per il cambio di sezione tra task-note-notifiche
   *   due click consecutivi su una sezione chiudono il drawer
   */
  const handleRightDrawerToggle = () => {
    if (activeRightItem === 'tasks' && rightDrawerOpen) {
      dispatch(setRightDrawerOpen(false));
    } else {
      dispatch(setActiveRightItem('tasks'));
      dispatch(setRightDrawerOpen(true));
    }
  };

  // get delle conferme di lettura dei task
  const getTasksReadings = useEndpoint<{ data: string[] }, 'get'>({
    method: 'get',
    endpoint: '/tasks/readings',
    queryKey: 'get-tasks-readings-btn'
  });

  const taskReadings = getTasksReadings.data?.data.data;

  return (
    <>
      {/* se ci sono task da visualizzare viene messo un badge rosso */}
      {taskReadings?.length ? (
        <Badge
          color="error"
          variant="dot"
          sx={{
            '& .MuiBadge-badge': {
              right: 4,
              top: 4
            },
            mr: 3
          }}
        >
          <Chip
            // click sul chip apre il drawer su quella sezione, secondo click consecutivo lo chiude
            onClick={handleRightDrawerToggle}
            label="Task"
            avatar={<Avatar sx={{ bgcolor: `${theme.palette.primary.main}` }}>{tasksCount}</Avatar>}
            size="large"
            sx={{
              cursor: 'pointer',
              '& .MuiChip-label': {
                fontWeight: '700',
                fontSize: '13px'
              },
              bgcolor: `${theme.palette.action.hover}`
            }}
          />
        </Badge>
      ) : (
        // altrimenti solo il chip con dentro il numero di task di quella sezione
        <Chip
          // click sul chip apre il drawer su quella sezione, secondo click consecutivo lo chiude
          onClick={handleRightDrawerToggle}
          label="Task"
          avatar={<Avatar sx={{ bgcolor: `${theme.palette.primary.main}` }}>{tasksCount}</Avatar>}
          size="large"
          sx={{
            cursor: 'pointer',
            '& .MuiChip-label': {
              fontWeight: '700',
              fontSize: '13px'
            },
            mr: 3,
            bgcolor: `${theme.palette.action.hover}`
          }}
        />
      )}
    </>
  );
};

export default Task;
