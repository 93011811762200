import { lazy } from 'react';
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';
import PermissionsGuard from 'utils/route-guard/PermissionsGuard';
import { WebsocketProvider } from 'components/custom/Websocket';
import Loadable from 'components/third-party/Loadable';

// manutenzione
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));

// Analytics
const Analytics = Loadable(lazy(() => import('pages/analytics')));

//Autopilot
const Autopilot = Loadable(lazy(() => import('pages/autopilot')));
const NewTarget = Loadable(lazy(() => import('pages/autopilot/tabs/targets/new-target')));
const NewFlux = Loadable(lazy(() => import('pages/autopilot/tabs/fluxs/new-flux')));
const DeleteNode = Loadable(lazy(() => import('pages/autopilot/shared/delete-node')));
const CreateEvent = Loadable(lazy(() => import('pages/autopilot/tabs/fluxs/new-flux/form/events/create-event')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('pages/dashboard')));

// Documents
const AllDocuments = Loadable(lazy(() => import('pages/documents')));

// Collaborators
const Collaborators = Loadable(lazy(() => import('pages/collaborators')));
const NewCollaborator = Loadable(lazy(() => import('pages/collaborators/new-collaborator')));
const CollaboratorAccount = Loadable(lazy(() => import('pages/collaborators/collaborator')));

// Buildings
const Buildings = Loadable(lazy(() => import('pages/buildings')));
const Building = Loadable(lazy(() => import('pages/buildings/building')));

// Orders
const Orders = Loadable(lazy(() => import('pages/orders')));
const Order = Loadable(lazy(() => import('pages/orders/order')));

// Users (clienti)
const FastUsers = Loadable(lazy(() => import('pages/users/fast')));
const SwitchoUsers = Loadable(lazy(() => import('pages/users/switcho')));
const Users = Loadable(lazy(() => import('pages/users/platform')));
const UserAccount = Loadable(lazy(() => import('pages/users/platform/user')));

// Referral codes
const ReferralCodes = Loadable(lazy(() => import('pages/referral-codes')));

// Faqs
const Faqs = Loadable(lazy(() => import('pages/faqs')));

// Partners
const Partners = Loadable(lazy(() => import('pages/partners')));

// Impostazioni
const Settings = Loadable(lazy(() => import('pages/settings')));

//Log
const Logs = Loadable(lazy(() => import('pages/log')));

// ==============================|| MAIN ROUTING ||============================== //

const ProtectedRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        // istanza del websocket
        <WebsocketProvider>
          {/* guardia di autorizzazione per le rotte private */}
          <AuthGuard>
            {/* guardia dei permessi necessari per poter vedere componenti / usare endpoint - validazione lato client */}
            <PermissionsGuard>
              {/* layout grafico utilizzato per tutte le rotte protette */}
              <MainLayout />
            </PermissionsGuard>
          </AuthGuard>
        </WebsocketProvider>
      ),
      children: [
        {
          // dashboard principale dell'app, rotta base
          path: 'dashboard',
          element: <Dashboard />
        },
        {
          // dati analytics dell'andamento ordini / registrazioni / utilizzo dell'app cliente
          path: 'analytics',
          element: <Analytics />
        },
        {
          // tutti gli utenti che utilizzano il backoffice
          path: 'collaborators',
          element: <Collaborators />,
          children: [
            {
              // creazione di un nuovo collaboratore
              path: 'new',
              element: <NewCollaborator />
            },
            {
              // pagina profilo del collaboratore
              path: ':id/:fullname',
              element: <CollaboratorAccount />
            },
            {
              // pagina profilo personale
              path: 'me',
              element: <CollaboratorAccount />
            }
          ]
        },
        {
          // tutti i clienti dell'app
          path: 'users',
          element: <Users />,
          children: [
            {
              // pagina profilo del cliente
              path: ':id/:fullname',
              element: <UserAccount />
            }
          ]
        },
        {
          // tutti i lead di una piattaforma di test marketing, per testare
          // la fattibilità e appetibilità di una specifica idea di business
          // -- da sistemare --
          // ancora da implementare
          path: 'switcho-users',
          element: <SwitchoUsers />
        },
        {
          // tutti i lead che derivano da funnel dell'agenzia di marketing
          // è praticamente solo una mail
          path: 'fast-registration-users',
          element: <FastUsers />
        },
        {
          // gli ordini (contrattazioni) avviate tra i clienti e buyplus
          path: 'orders',
          element: <Orders />,
          children: [
            {
              // singolo ordine legato alla struttura
              path: ':buildingId/:buildingName/:orderId',
              element: <Order />
            }
          ]
        },
        {
          // tutte le strutture registrate dai clienti
          path: 'buildings',
          element: <Buildings />,
          children: [
            {
              // singola struttura gestita dal cliente
              path: ':buildingId/:buildingName',
              element: <Building />
            }
          ]
        },
        // tabella di tutti i referral code istanziati
        // ancora da discutere l'implementazione lato business
        { path: 'referral-codes', element: <ReferralCodes /> },

        // questa sezione gestisce le FAQ che si vedono sull'app cliente
        // tutte le FAQ visualizzate sull'app del cliente
        { path: 'faq', element: <Faqs /> },

        // impostazioni del backoffice e dell'app
        { path: 'settings', element: <Settings /> },

        // sezione di gestione dei partner di credito di BuyPlus e loro impostazioni
        { path: 'partners', element: <Partners /> },

        // sulla loro tipologia, punto di richiesta, obbligatorietà
        { path: 'platform-contracts', element: <AllDocuments /> },

        // tutti i log di tutte le azioni svolte
        { path: 'log', element: <Logs /> },
        {
          // autopilot.
          // strumento di automatizzazione dell'intero sistema BuyPlus da parte dei collaboratori
          // ha a disposizione tutti i dati del database
          // dovrà permettere di creare eventi -> azioni programmatici anche complessi
          path: 'autopilot',
          element: <Autopilot />,
          children: [
            // è la creazione del flusso
            // il flusso è un susseguirsi di nodi che vengono eseguiti alla fine dell'esecuzione del precedente
            // esiste un nodo timer che "blocca" l'esecuzione fino al momento in cui la condizione del nodo stesso
            // non è soddisfatta
            // sezione di creazione di un nuovo flusso da inserire nel sistema Autopilot
            { path: 'create-flux', element: <NewFlux /> },
            // sezione di visualizzazione e modifica del flusso
            { path: ':fluxID', element: <NewFlux /> },
            // il target è un nodo filtro, si sceglie a chi applicare un determinato flusso
            // il componente NewTarget è riutilizzabile, la differenza è nel fatto che il flusso è già stato creato o meno
            // sezione di creazione di un target da dare in pasto al flusso (filtro)
            { path: 'create-target-preset', element: <NewTarget /> },
            // sezione di visualizzazione e modifica del target
            { path: 'target/:targetID', element: <NewTarget /> },
            // creazione del nodo evento, gli eventi sono i creatori di esecuzione di flussi
            // sono loro che danno l'input per attivare un flusso
            // e possono essere di tipo:
            // evento scatenato dal cliente (può essere il click di un bottone, o l'invio di un ordine)
            // evento temporizzato, c'è magari una programmazione da seguire, stile mailing list
            // sezione di creazione di un evento scatenante del flusso
            { path: 'create-event', element: <CreateEvent /> },
            // eliminazione di un nodo (evento/filtro/timer/azione)
            { path: 'delete-node', element: <DeleteNode /> }
          ]
        },
        {
          // rotte ereditate, servono per mettere in manutenzione l'app
          path: '/maintenance',
          element: <CommonLayout />,
          children: [
            {
              path: '404',
              element: <MaintenanceError />
            },
            {
              path: '500',
              element: <MaintenanceError500 />
            },
            {
              path: 'under-construction',
              element: <MaintenanceUnderConstruction />
            }
          ]
        }
      ]
    }
  ]
};

export default ProtectedRoutes;
