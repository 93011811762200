import { Avatar, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import useModal from 'hooks/useModal';
import { Comment } from 'types/dto/task.dto';
import { dispatch, useSelector } from 'store';
import { FormikErrors } from 'formik';
import { KeyboardEvent, useState } from 'react';
import { AskConfirmation } from 'components/custom/ask-confirmation';
import { useEndpoint } from 'hooks/useEndpoint';
import { openSnackbar } from 'store/reducers/snackbar';
import { avatarInitials } from 'utils/formattation';
import { useRefetchQueries } from 'hooks/useRefetchQueries';

interface Props {
  taskID: number;
  editComments: (Comment & {
    edit: boolean;
  })[];
  commentToSend: string;
  setValues: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          commentsToSend: Comment[];
          editComments: (Comment & {
            edit: boolean;
          })[];
        }>
      >;
  handleSaveEditComment: (index: number) => void;
  handleEditComment: (index: number) => void;

  commentPressEnter: (e: KeyboardEvent<HTMLDivElement>, id: number) => void;
  setCommentToEdit: React.Dispatch<React.SetStateAction<number | undefined>>;
}
const Comments = ({
  taskID,
  editComments,
  commentToSend,
  setValues,
  setCommentToEdit,
  handleSaveEditComment,
  handleEditComment,
  commentPressEnter
}: Props) => {
  const theme = useTheme();
  const { refetchQueries } = useRefetchQueries();
  const { user } = useSelector((state) => state.auth);
  const { openModal } = useModal();
  const [commentToDelete, setCommentToDelete] = useState<number>();

  const deleteComment = useEndpoint<undefined, 'delete'>({
    method: 'delete',
    endpoint: `/task-comments/${commentToDelete}`,
    mutationKey: `delete-comment-${commentToDelete}`,
    options: {
      onSuccess: () => {
        dispatch(
          openSnackbar({
            message: 'Commento rimosso con successo.',
            open: true,
            variant: 'success',
            key: `delete-comment-${commentToDelete}`
          })
        );
        setCommentToDelete(undefined);
        refetchQueries([`get-task-${taskID}`]);
      }
    }
  });
  return (
    <Box sx={{ mt: 2 }}>
      {editComments.map((comment, i) => (
        <Box key={`comment-${i}`} className="flex ali-center" width={'100%'} my={2} position="relative">
          <Avatar sx={{ mr: 2 }}>{avatarInitials(`${comment.user.firstname} ${comment.user.lastname}`)}</Avatar>
          <Box
            border={`1px solid ${theme.palette.divider}`}
            borderRadius={2}
            width="100%"
            p={1}
            sx={{
              '&.MuiBox-root:hover': {
                border: `1px solid ${theme.palette.primary.main}`
              },
              '.MuiBox-root:hover > & > .date': {
                display: 'none'
              },
              '.MuiBox-root:hover > & > .modify': {
                display: 'block'
              },
              '.MuiBox-root:hover > & > .separator': {
                display: 'block'
              },
              '.MuiBox-root:hover > & > .delete': {
                display: 'block'
              }
            }}
          >
            <Box className="flex just-btwn">
              <Box display="flex">
                <Typography variant="caption" sx={{ mr: 0.5, fontWeight: 700 }}>
                  {comment.user.firstname} {comment.user.lastname}
                </Typography>
                <Typography variant="caption">ha commentato:</Typography>
              </Box>
            </Box>
            <Typography className="date" variant="caption" position="absolute" right={8} top={8}>
              {dayjs(comment.at).format(`DD/MM/YYYY HH:mm`)}
            </Typography>
            {user?.id === comment.user.id &&
              (comment.edit ? (
                <>
                  <Typography
                    className="modify"
                    variant="caption"
                    sx={{ position: 'absolute', display: 'none', right: 68, top: 8, cursor: 'pointer' }}
                    onClick={() => {
                      handleSaveEditComment(i);
                    }}
                  >
                    Salva
                  </Typography>
                  <Typography
                    className="separator"
                    variant="caption"
                    sx={{ position: 'absolute', display: 'none', right: 58, top: 8, cursor: 'default' }}
                  >
                    -
                  </Typography>

                  <Typography
                    className="delete"
                    variant="caption"
                    sx={{ position: 'absolute', display: 'none', right: 10, top: 8, cursor: 'pointer' }}
                    onClick={() => {
                      setCommentToEdit(undefined);
                      setValues(`editComments[${i}].edit`, false);
                    }}
                  >
                    Annulla
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    className="modify"
                    variant="caption"
                    sx={{ position: 'absolute', display: 'none', right: 68, top: 8, cursor: 'pointer' }}
                    onClick={() => {
                      setCommentToEdit(comment.id);
                      handleEditComment(i);
                    }}
                  >
                    Modifica
                  </Typography>
                  <Typography
                    className="separator"
                    variant="caption"
                    sx={{ position: 'absolute', display: 'none', right: 58, top: 8, cursor: 'default' }}
                  >
                    -
                  </Typography>

                  <Typography
                    className="delete"
                    variant="caption"
                    sx={{ position: 'absolute', display: 'none', right: 10, top: 8, cursor: 'pointer' }}
                    onClick={() => {
                      setCommentToDelete(comment.id);
                      openModal({
                        title: 'Eliminazione commento',
                        content: (
                          <AskConfirmation
                            confirmationText="Sei sicuro di voler eliminare questo commento?"
                            onConfirmCallback={() => deleteComment.mutate(undefined)}
                            onDismissCallback={() => setCommentToDelete(undefined)}
                          />
                        )
                      });
                    }}
                  >
                    Elimina
                  </Typography>
                </>
              ))}

            {comment.edit ? (
              <TextField
                size="small"
                fullWidth
                minRows={1}
                maxRows={3}
                multiline
                value={commentToSend ? commentToSend : editComments[i].description}
                onChange={(e) => {
                  setValues(`editComments[${i}].description`, e.target.value);
                  setValues('commentToSend', e.target.value);
                }}
                onKeyDown={(e) => commentPressEnter(e, i)}
              />
            ) : (
              <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', width: '100%', mt: 1 }}>
                {comment.description}
              </Typography>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default Comments;
