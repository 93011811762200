import { Box, Skeleton, useTheme } from '@mui/material';

interface Props {
  pageSize: number;
}
export const NotificationsLoading = ({ pageSize }: Props) => {
  const theme = useTheme();
  const rep = () => {
    const result = [];
    for (let i = 0; i < pageSize; i++) {
      result.push(i);
    }
    return result;
  };

  return (
    <>
      {rep().map((id) => (
        <Box key={id} borderBottom={`1px solid ${theme.palette.divider}`} className="flex just-btwn">
          <Box className="flex ali-center">
            <Skeleton animation="wave" variant="circular" sx={{ margin: 0.8, width: 40, height: 40 }} />
            <Box sx={{ ml: 1 }}>
              <Skeleton animation="wave" sx={{ mr: 0.5, width: 100 }} />
              <Box display="flex">
                <Skeleton animation="wave" sx={{ mr: 0.5, width: 50 }} />
                <Skeleton animation="wave" sx={{ mr: 0.5, width: 50 }} />
              </Box>
            </Box>
          </Box>
          <Box className="flex ali-center">
            <Skeleton animation="wave" sx={{ mr: 2, width: 100 }} />
          </Box>
        </Box>
      ))}
    </>
  );
};
