// ==============================|| LOGO SVG ||============================== //

const LogoMain = () => {
  return (
    <>
      <svg width="170" height="36" viewBox="0 0 170 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.8389 27.1863H11.3562V23.4507C11.3562 19.2493 7.93702 15.8301 3.73559 15.8301H0V11.3474H3.73559C10.4069 11.3474 15.8389 16.7794 15.8389 23.4507V27.1863Z"
          fill="#094754"
        />
        <path
          d="M27.1951 15.8389H23.4595C16.7882 15.8389 11.3562 10.4069 11.3562 3.73559V0H15.8389V3.73559C15.8389 7.93702 19.2581 11.3562 23.4595 11.3562H27.1951V15.8389Z"
          fill="#094754"
        />
        <path
          d="M54.4429 17.4738C54.4429 13.8436 52.1049 11.3034 48.7736 11.3034C45.4423 11.3034 43.0164 13.8085 43.0164 17.4738C43.0164 21.139 45.3544 23.6441 48.7736 23.6441C52.1928 23.6441 54.4429 21.1039 54.4429 17.4738ZM58.9871 17.4738C58.9871 23.4771 55.067 27.6873 49.4416 27.6873C46.8575 27.6873 44.3964 26.4392 43.1834 24.5582V27.1863H38.6743V0H43.1746V10.3893C44.3876 8.51714 46.9717 7.26022 49.7229 7.26022C55.2779 7.26022 58.9871 11.3474 58.9871 17.4738Z"
          fill="#094754"
        />
        <path
          d="M61.1054 19.9788V7.76123H65.6497V18.8537C65.6497 21.9828 66.6517 23.8199 69.6138 23.8199C73.0769 23.8199 74.325 21.5258 74.325 18.7307V7.76123H78.8692V27.1951H74.325V24.4879C73.156 26.571 71.1168 27.6961 68.3217 27.6961C64.0675 27.6873 61.1054 25.191 61.1054 19.9788Z"
          fill="#094754"
        />
        <path
          d="M95.6135 7.76123H100.158V27.2742C100.158 30.3154 99.2436 32.0997 97.6175 33.6818C96.0354 35.1409 93.7852 35.932 91.0253 35.932C88.2653 35.932 85.8921 35.22 84.354 33.9279C82.974 32.715 82.2269 31.0098 82.1829 28.8827H86.7711C86.9821 31.0098 88.0192 32.1788 90.9374 32.1788C93.6446 32.1788 95.6047 31.0713 95.6047 26.9402V24.4791C94.3917 26.5622 92.2295 27.6873 89.3465 27.6873C84.5473 27.6873 82.1741 24.2681 82.1741 19.6008V7.76123H86.7184V18.6428C86.7184 21.517 87.6764 23.688 90.9286 23.688C94.1807 23.688 95.5959 21.1478 95.5959 17.8078V7.76123H95.6135Z"
          fill="#094754"
        />
        <path
          d="M119.381 17.4738C119.381 13.8436 117.043 11.3034 113.711 11.3034C110.38 11.3034 107.954 13.8085 107.954 17.4738C107.954 21.139 110.292 23.6441 113.711 23.6441C117.13 23.6441 119.381 21.1039 119.381 17.4738ZM123.925 17.4738C123.925 23.4771 120.005 27.6873 114.379 27.6873C111.795 27.6873 109.334 26.4392 108.121 24.5582V34.9124H103.621V7.75244H108.121V10.3805C109.334 8.50835 111.918 7.25144 114.669 7.25144C120.216 7.26022 123.925 11.3474 123.925 17.4738Z"
          fill="#094754"
        />
        <path d="M130.798 0H126.254V27.1863H130.798V0Z" fill="#094754" />
        <path
          d="M134.094 19.9788V7.76123H138.639V18.8537C138.639 21.9828 139.641 23.8199 142.603 23.8199C146.066 23.8199 147.314 21.5258 147.314 18.7307V7.76123H151.858V27.1951H147.314V24.4879C146.145 26.571 144.106 27.6961 141.311 27.6961C137.057 27.6873 134.094 25.191 134.094 19.9788Z"
          fill="#094754"
        />
        <path
          d="M154.073 21.2269H158.617C158.617 22.897 160.244 24.066 162.582 24.066C164.296 24.066 165.377 23.1519 165.377 21.7719C165.377 20.6908 164.92 20.0227 162.836 19.4778L158.96 18.4318C156.253 17.6847 154.706 15.7246 154.706 12.9735C154.706 9.67737 157.835 7.26023 162.168 7.26023C166.924 7.26023 169.842 9.55432 169.842 13.3075H165.377C165.377 11.6814 164.129 10.7233 162.001 10.7233C160.375 10.7233 159.206 11.5583 159.206 12.7274C159.206 13.7733 159.83 14.4765 161.623 14.9775L165.5 16.1026C168.251 16.8936 170 18.8977 170 21.6488C170 25.4459 167.038 27.6961 162.327 27.6961C157.58 27.6873 154.073 24.9361 154.073 21.2269Z"
          fill="#094754"
        />
      </svg>
    </>
  );
};

export default LogoMain;
