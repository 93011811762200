import { CloseOutlined } from '@mui/icons-material';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEndpoint } from 'hooks/useEndpoint';
import { dispatch } from 'store';
import { setActiveRightItem, setRightDrawerOpen } from 'store/reducers/menu';
import { CountedData } from 'types/counted-data';
import { ResourceDTO } from 'types/dto/resource.dto';
import { SendTaskDTO } from 'types/dto/task.dto';
import ArrowBack from 'assets/images/icons/navigation/ArrowBack';
import { SubjectFilter } from 'components/custom/async-selectors/subject-filter';
import useModal from 'hooks/useModal';
import { AskConfirmation } from 'components/custom/ask-confirmation';
import { setNewTaskTitle } from 'store/reducers/helpers';
import { SetFormikValues, SetSectionFilterProps, SetSubjectProps, SubjectProps } from '../../types/tasks';

interface Props {
  formikValues: SendTaskDTO;
  setFormikValues: SetFormikValues;
  endpoint: string;
  subject: SubjectProps;
  setSubject: SetSubjectProps;
  setSectionFilter?: SetSectionFilterProps;
  isNewTask: boolean;
}
const Header = ({ formikValues, setFormikValues, endpoint, subject, setSubject, setSectionFilter, isNewTask }: Props) => {
  const { openModal } = useModal();
  const getResources = useEndpoint<CountedData<ResourceDTO>, 'get'>({
    method: 'get',
    endpoint: '/tasks/resources',
    queryKey: 'get-tasks-resources'
  });
  return (
    <Box className="right-drawer-header">
      <Box className="header-container">
        <Box className="flex ali-center">
          <CloseOutlined
            className="clickable"
            style={{ fontSize: 22, marginRight: 14, color: '#9aa4a7' }}
            onClick={() => {
              dispatch(setRightDrawerOpen(false));
            }}
          />
          <Button
            onClick={() => {
              if (isNewTask) {
                openModal({
                  title: 'Conferma azione',
                  content: (
                    <AskConfirmation
                      confirmationText="Perderai tutte le informazioni inserite, sei sicuro di voler tornare indietro?"
                      onConfirmCallback={() => {
                        dispatch(setActiveRightItem('tasks'));
                        dispatch(setNewTaskTitle(''));
                      }}
                    />
                  )
                });
                return;
              }
              dispatch(setActiveRightItem('tasks'));
              dispatch(setNewTaskTitle(''));
            }}
            className="textSecondary"
          >
            <ArrowBack sx={{ mb: -1.8, mr: -1 }} /> Indietro
          </Button>
        </Box>
        <Box className="flex ali-center">
          <FormControl size="small" sx={{ width: 230, mr: isNewTask ? 2 : -14, mt: isNewTask ? 0 : 2 }}>
            <InputLabel id="resource-selector">Sezione</InputLabel>
            <Select
              labelId="resource-selector"
              id="resource-selector"
              disabled={isNewTask ? false : true}
              className={isNewTask ? '' : 'hide-section-input'}
              value={JSON.stringify(formikValues?.resource ?? null)}
              onChange={(e: SelectChangeEvent<string>) => {
                setFormikValues('data.resource', JSON.parse(e.target.value));
                setFormikValues('data.subject', []);
                setSectionFilter!(JSON.parse(e.target.value));
              }}
              label="Sezione"
              MenuProps={{ sx: { zIndex: 1700 } }}
            >
              <MenuItem value={'null'}>Generale</MenuItem>
              {getResources.data?.data &&
                getResources.data?.data.data.map((r) => (
                  <MenuItem key={r.name} value={JSON.stringify(r.slug)}>
                    {r.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: 230, mr: isNewTask ? 0 : -10, mt: isNewTask ? 0 : 2 }}>
            <SubjectFilter
              key={'task-subject-filter'}
              mt={0}
              endpoint={endpoint}
              subject={subject}
              setSubject={setSubject}
              taskDefaultValue={formikValues.subject?.[0]}
              setNewTask={setFormikValues}
            />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
