import { Box, Button, Menu } from '@mui/material';
import SearchBar from 'components/custom/search-bar';
import CreateTask from './create-task';
import { dispatch } from 'store';
import { setActiveRightItem } from 'store/reducers/menu';
import { MouseEvent, useState } from 'react';

export const TaskAndSearch = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className="vertical-standard-margin">
      <Box className="flex just-btwn ali-center">
        <Button size="small" variant="contained" onClick={handleClick}>
          crea task
        </Button>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{ zIndex: 3000, '.MuiPaper-root': { background: 'white', width: '700px', boxShadow: '-1px 2px 2px rgba(0,0,0,.2)' } }}
        >
          <CreateTask
            onNewTaskChange={() => {
              setAnchorEl(null);
              dispatch(setActiveRightItem('new-task'));
            }}
          />
        </Menu>
        <SearchBar width="50%" handleSetFilter={() => console.log('dadefinire')} />
      </Box>
    </Box>
  );
};
