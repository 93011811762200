import { Add } from '@mui/icons-material';
import { Box, IconButton, TextField } from '@mui/material';
import useModal from 'hooks/useModal';
import { KeyboardEvent } from 'react';
import { dispatch, useSelector } from 'store';
import { setNewTaskTitle } from 'store/reducers/helpers';

interface Props {
  onNewTaskChange: () => void;
}
const CreateTask = ({ onNewTaskChange }: Props) => {
  const { newTaskTitle } = useSelector((state) => state.helpers);
  const { closeModal } = useModal();

  const handleCreateTask = (e?: KeyboardEvent) => {
    if (e) {
      if (e.code === 'Enter') {
        e.preventDefault();
        onNewTaskChange();
        closeModal();
      }
      return;
    }
    onNewTaskChange();
    closeModal();
  };

  return (
    <Box className="popup-container">
      <TextField
        className="vertical-standard-margin"
        size="small"
        fullWidth
        autoFocus
        placeholder="Titolo task"
        value={newTaskTitle}
        slotProps={{
          input: {
            endAdornment: (
              <IconButton onClick={() => handleCreateTask()}>
                <Add />
              </IconButton>
            )
          }
        }}
        onKeyDown={handleCreateTask}
        onChange={(e) => dispatch(setNewTaskTitle(e.target.value))}
      />
    </Box>
  );
};

export default CreateTask;
