import { Box, Typography } from '@mui/material';
import TaskCollaboratorSelector from 'components/custom/async-selectors/task-collaborator-selector';
import { FormikErrors, useFormik } from 'formik';
import { ChangeEvent, useEffect } from 'react';
import { SendTaskDTO } from 'types/dto/task.dto';
import { StyledTextarea } from '../../shared-components/textarea';
import { Subscription } from 'types/dto/task.dto';
import _, { cloneDeep } from 'lodash';
interface Props {
  disabled: boolean;
  taskForm?: SendTaskDTO;
  setValues: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          data: SendTaskDTO | undefined;
        }>
      >;
}
export const CollaboratorsAndDescription = ({ disabled, taskForm, setValues }: Props) => {
  const subscriptionsForm = useFormik<{ assignee: Subscription[]; watchers: Subscription[] }>({
    initialValues: {
      assignee: taskForm?.subscriptions?.filter((a) => a.isWatcher === false) ?? [],
      watchers: taskForm?.subscriptions?.filter((w) => w.isWatcher) ?? []
    },
    onSubmit: (values) => {
      setValues('data.subscriptions', [
        ...(values.assignee?.map((a) => ({ id: a.id, isWatcher: a.isWatcher })) ?? []),
        ...(values.watchers?.map((a) => ({ id: a.id, isWatcher: a.isWatcher })) ?? [])
      ]);
    }
  });

  useEffect(() => {
    if (!_.isEqual(cloneDeep(subscriptionsForm.values), cloneDeep(subscriptionsForm.initialValues))) {
      subscriptionsForm.handleSubmit();
    }
  }, [subscriptionsForm.values]);
  return (
    <>
      <Box display="flex" flexWrap={'wrap'} sx={{ mt: 2 }}>
        <Box className="flex dir-col" sx={{ mr: 2, minWidth: '42%' }}>
          <Typography variant="caption" sx={{ fontWeight: 500 }} className="textSecondary">
            Gestito da:
          </Typography>
          <TaskCollaboratorSelector
            key="assignee-selector"
            disabled={disabled}
            isWatcher={false}
            values={taskForm!}
            collaboratorArray={subscriptionsForm.values.assignee}
            otherCollaboratorArray={subscriptionsForm.values.watchers}
            setCollaboratorArray={subscriptionsForm.setFieldValue}
          />
        </Box>
        <Box className="flex dir-col" sx={{ minWidth: '42%', mr: 2 }}>
          <Typography variant="caption" className="textSecondary" sx={{ fontWeight: 500 }}>
            Supervisionato da:
          </Typography>
          <TaskCollaboratorSelector
            key="watchers-selector"
            disabled={disabled}
            isWatcher={true}
            values={taskForm!}
            collaboratorArray={subscriptionsForm.values.watchers}
            otherCollaboratorArray={subscriptionsForm.values.assignee}
            setCollaboratorArray={subscriptionsForm.setFieldValue}
          />
        </Box>
      </Box>

      <Box className="vertical-standard-margin">
        <Typography sx={{ fontWeight: 700 }} variant="body2">
          Descrizione
        </Typography>
        <StyledTextarea
          minRows={4}
          autoFocus
          disabled={disabled}
          sx={{ mt: 1, fontSize: '0.9rem' }}
          value={taskForm?.description}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setValues('data.description', e.target.value);
          }}
        />
      </Box>
    </>
  );
};
