import React, { createContext, useCallback } from 'react';
import AsyncLock from 'async-lock';
import { AuthContextType, UserProfile } from 'types/auth';
import axios from 'axios';
import { queryClient } from 'App';
import { useDispatch, useSelector } from 'store';
import dayjs from 'dayjs';
import { setLogin, setLogout } from 'store/reducers/auth';

// questa è l'istanza di Axios per l'endpoint di autorizzazione
const axiosAuth = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL + 'server/' + process.env.REACT_APP_API_VERSION + '/auth'
});

type AxiosAuthResponseType = {
  accessToken: string;
  refreshToken: string;
  accessExpireAt: string;
  user: UserProfile;
};

const lock = new AsyncLock();
const REFRESH_LOCK = 'REFRESH_LOCK';
const LOGOUT_LOCK = 'LOGOUT_LOCK';

// ==============================|| AUTHENTICATION CONTEXT & PROVIDER ||============================== //

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const dispatch = useDispatch();
  // il rememberMe è un booleano (stringa 'true' | 'false') nel localStorage
  // viene settato al login tramite il checkbox "ricorda l'accesso da questo computer"
  const { rememberMe } = useSelector((state) => state.authorization);

  // endpoint di login, email(param1), password(param2), a risposta corretta del server
  // in base alla spunta o meno della casella "ricordati l'accesso"
  // -  nel localStorage viene settato lo "stayActive" a "true" | "false"
  // -  in base allo "stayActive" vengono scritti gli accessToken e il refreshToken
  //    o nel localStorage o nel sessionStorage
  const authEmailPasswordSignIn = useCallback(
    (email: string, password: string) =>
      axiosAuth<AxiosAuthResponseType>({
        method: 'post',
        url: '/welcome',
        data: { param1: email, param2: password }
      }).then((res) => {
        localStorage.setItem('stayActive', rememberMe.toString());

        if (localStorage.getItem('stayActive') === 'true') {
          localStorage.setItem('accessToken', res.data.accessToken);
          localStorage.setItem('refreshToken', res.data.refreshToken);
        } else {
          sessionStorage.setItem('accessToken', res.data.accessToken);
          sessionStorage.setItem('refreshToken', res.data.refreshToken);
        }

        const user = res.data.user;
        // vengono inseriti i dati utente nello store centralizzato di Redux
        dispatch(
          setLogin({
            isLoggedIn: true,
            id: user.id,
            email: user.email,
            phone: user.phone,
            firstname: user.firstname,
            lastname: user.lastname,
            permissions: user.permissions,
            imageUploadCode: user.imageUploadCode,
            lastEdit: user.lastEdit,
            super: user.super
          })
        );
        // viene settata la data di scadenza della validità del token
        localStorage['accessExpireAt'] = res.data.accessExpireAt;
      }),
    [rememberMe]
  );

  // funzione per validare il token
  const authTokenLogin = useCallback(
    () =>
      axiosAuth<AxiosAuthResponseType>({
        method: 'get',
        url: '/me',
        headers: {
          // prende il token da dove è stato scritto (localStorage/sessionStorage)
          Authorization: `Bearer ${rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']}`
        }
      })
        // con risposta positiva si settano i dati dello user nello store centralizzato Redux
        .then((res) => {
          const user = res.data.user;
          dispatch(
            setLogin({
              isLoggedIn: true,
              id: user.id,
              email: user.email,
              phone: user.phone,
              firstname: user.firstname,
              lastname: user.lastname,
              permissions: user.permissions,
              imageUploadCode: user.imageUploadCode,
              lastEdit: user.lastEdit,
              super: user.super
            })
          );
        })
        // qualsiasi altra risposta non è valida
        .catch(async (error) => {
          // se la risposta del server è che il token è invalido allora si fa il refresh del token
          if (error.response?.data?.message === 'invalid_token') {
            // rimuoviamo l'expireAt del token per il passaggio successivo nella funzione refreshToken
            localStorage.removeItem('accessExpireAt');
            await refreshToken();
            // qualsiasi altra risposta rispetto a "invalid_token" non è contemplata
            // l'utente viene buttato fuori
          } else {
            await logout();
          }
        }),
    // al cambio di questi parametri viene rieseguito tutto
    [rememberMe, localStorage['accessToken'], sessionStorage['accessToken']]
  );

  // funzione per refreshare il token
  const refreshToken = useCallback(async () => {
    return lock.acquire(REFRESH_LOCK, async () => {
      // avendo l'expireAt in localStorage sappiamo anche sul client se è da refreshare
      if (localStorage['accessExpireAt'] && dayjs().isBefore(localStorage['accessExpireAt'])) return;
      await axiosAuth<AxiosAuthResponseType>({
        method: 'post',
        url: '/refresh',
        // dipende sempre da dove abbiamo salvato il token (localStorage | sessionStorage)
        headers: { Authorization: `Bearer ${rememberMe ? localStorage['refreshToken'] : sessionStorage['refreshToken']}` }
      })
        .then(({ data }) => {
          // set dei dati utente nello storage centralizzato, Redux
          const user = data.user;
          dispatch(
            setLogin({
              isLoggedIn: true,
              id: user.id,
              email: user.email,
              phone: user.phone,
              firstname: user.firstname,
              lastname: user.lastname,
              permissions: user.permissions,
              imageUploadCode: user.imageUploadCode,
              lastEdit: user.lastEdit,
              super: user.super
            })
          );
          // settiamo nuovamente la scadenza del token sul client
          localStorage['accessExpireAt'] = data.accessExpireAt;

          // in base alla scelta iniziale (checkbox "ricordami") settiamo i dati
          // nel local o sessionStorage
          if (rememberMe) {
            localStorage['accessToken'] = data.accessToken;
            localStorage['refreshToken'] = data.refreshToken;
          } else {
            sessionStorage['accessToken'] = data.accessToken;
            sessionStorage['refreshToken'] = data.refreshToken;
          }
        })
        // qualsiasi altra risposta ci butta fuori
        .catch(async () => {
          await logout();
        });

      return;
    });
  }, [rememberMe, localStorage['refreshToken'], sessionStorage['refreshToken']]);

  // funzione per sloggare l'utente
  const logout = useCallback(async () => {
    return lock.acquire(LOGOUT_LOCK, async () => {
      // se non abbiamo i token, siamo già fuori
      if (localStorage['accessToken'] === '' && sessionStorage['accessToken'] === '') return;
      await axiosAuth<{ result: string }>({
        method: 'post',
        url: '/logout',
        headers: { Authorization: `Bearer ${rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']}` }
      }).finally(() => {
        // resetta tutti i token, ovunque essi siano
        localStorage['accessToken'] = '';
        localStorage['refreshToken'] = '';
        sessionStorage['refreshToken'] = '';
        sessionStorage['accessToken'] = '';
        localStorage['accessExpireAt'] = '';

        dispatch(setLogout());
        // svuota la cache di react-query
        queryClient.clear();
      });
    });
  }, [rememberMe, localStorage['accessToken'], sessionStorage['accessToken']]);

  // enpoint per resettare la password, ma non dovrebbe essere utilizzato davvero
  // le password su backoffice sono controllate dai super, e dall'utente stesso
  // le password si modificano con una patch
  // c'è perchè è ereditato
  const forgotPassword = useCallback(
    (email: string) =>
      axiosAuth({
        method: 'post',
        url: '/password-reset',
        data: {
          email
        }
      }),
    []
  );
  // endpoint per resettare la password, ma non dovrebbe essere utilizzato davvero
  // le password su backoffice sono controllate dai super, e dall'utente stesso
  // le password si modificano con una patch
  // c'è perchè ereditato
  const resetPassword = useCallback(
    (password: string, newPassword: string, code: string) =>
      axiosAuth({
        method: 'put',
        url: `/password-reset/${code}`,
        data: {
          password,
          passwordConfirm: newPassword
        }
      }),
    []
  );
  // le password su backoffice sono controllate dai super, e dall'utente stesso
  // le password si modificano con una patch
  // c'è perchè ereditato
  const editPassword = useCallback(
    (oldPassword: string, newPassword: string, confirmNewPassword: string) =>
      axiosAuth({
        method: 'patch',
        url: '/password',
        headers: { Authorization: `Bearer ${rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken']}` },
        data: {
          oldPassword,
          newPassword,
          confirmNewPassword
        }
      }),
    [rememberMe, localStorage['accessToken'], sessionStorage['accessToken']]
  );

  return (
    <AuthContext.Provider
      value={{
        refreshToken,
        authEmailPasswordSignIn,
        authTokenLogin,
        logout,
        forgotPassword,
        resetPassword,
        editPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
