import { Comment, SendTaskDTO } from 'types/dto/task.dto';
import AddComment from './add-comment';
import Comments from './Comments';
import { FormikErrors, useFormik } from 'formik';
import { KeyboardEvent, useState } from 'react';
import { useEndpoint } from 'hooks/useEndpoint';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { useRefetchQueries } from 'hooks/useRefetchQueries';

interface Props {
  taskID: number;
  comments: Comment[];
  setValues: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          data: SendTaskDTO | undefined;
        }>
      >;
}
export const TaskComments = ({ taskID, comments }: Props) => {
  const { refetchQueries } = useRefetchQueries();

  const [commentToEdit, setCommentToEdit] = useState<number>();

  const editComment = useEndpoint<{ description: string }, 'patch'>({
    method: 'patch',
    endpoint: `/task-comments/${commentToEdit}`,
    mutationKey: `edit-comment-${commentToEdit}`,
    options: {
      onSuccess: ({ data }) => {
        refetchQueries([`get-task-${taskID}`]);
        dispatch(
          openSnackbar({
            message: `Commento modificato con successo.`,
            open: true,
            variant: 'success',
            key: `edit-comment-${commentToEdit}`
          })
        );
        setCommentToEdit(undefined);
        commentsForm.setFieldValue(`editComments[${data.data.id}].edit`, false);
      }
    }
  });

  const commentsForm = useFormik<{
    commentToSend: string;
    editComments: (Comment & { edit: boolean })[];
  }>({
    enableReinitialize: true,
    initialValues: {
      editComments: comments.map((c) => ({ ...c, edit: false })),
      commentToSend: ''
    },
    onSubmit: (values) => {
      editComment.mutate({ description: values.commentToSend });
    }
  });
  const handleEditComment = (index: number) => {
    commentsForm.setValues(commentsForm.initialValues);
    commentsForm.setFieldValue(`editComments[${index}].edit`, true);
  };

  const handleSaveEditComment = (index: number) => {
    commentsForm.setFieldValue(`editComments[${index}].edit`, false);
    commentsForm.handleSubmit();
  };

  const commentPressEnter = (e: KeyboardEvent<HTMLDivElement>, index: number) => {
    if (e.code === 'Enter') {
      e.preventDefault();
      commentsForm.setFieldValue(`editComments[${index}].edit`, false);
      commentsForm.handleSubmit();
    }
  };

  return (
    <>
      <AddComment
        taskID={taskID}
        comments={comments ?? []}
        setEditComments={commentsForm.setFieldValue}
        init={commentsForm.initialValues.editComments}
      />
      <Comments
        taskID={taskID}
        setCommentToEdit={setCommentToEdit}
        commentToSend={commentsForm.values.commentToSend}
        editComments={commentsForm.values.editComments}
        setValues={commentsForm.setFieldValue}
        handleEditComment={handleEditComment}
        handleSaveEditComment={handleSaveEditComment}
        commentPressEnter={commentPressEnter}
      />
    </>
  );
};
