import { Box, Checkbox, FormControl, IconButton } from '@mui/material';
import { ChangeEvent } from 'react';
import { SendTaskDTO, Todo } from 'types/dto/task.dto';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { DragIndicator } from '@mui/icons-material';
import { FormikErrors } from 'formik';
import _ from 'lodash';
import DeleteIcon from 'assets/images/icons/navigation/Delete';
import { TodoTextarea } from './textarea';

interface Props {
  todos: (
    | Todo
    | {
        title: string;
        done: boolean;
      }
  )[];
  setTodos: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          data: SendTaskDTO | undefined;
        }>
      >;
  disabled: boolean;
}
const TodoList = ({ todos, setTodos, disabled }: Props) => {
  const handleChangeCheckbox = (i: number) => {
    const allTodo = _.cloneDeep(todos);
    allTodo[i].done = !allTodo[i].done;
    setTodos('data.todos', allTodo);
  };

  const handleChangeTitle = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, i: number) => {
    const allTodo = _.cloneDeep(todos);
    allTodo[i].title = e.target.value;
    setTodos('data.todos', allTodo);
  };

  const handleDeleteTodo = (i: number) => {
    const filtered = _.cloneDeep(todos);
    filtered.splice(i, 1);
    setTodos('data.todos', filtered);
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const newTodos = Array.from(todos);
    const [reorderedItem] = newTodos.splice(result.source.index, 1);
    newTodos.splice(result.destination.index, 0, reorderedItem);

    setTodos('todos', newTodos);
  };

  return (
    <Box pt={2}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="todo-list">
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef} zIndex={3000}>
              {todos.length ? (
                todos.map((todo: Todo | { title: string; done: boolean }, i: number) => (
                  <Draggable key={`todo-item-${i}`} draggableId={`todo-id-${i}`} index={i}>
                    {(provided) => (
                      <Box {...provided.draggableProps} ref={provided.innerRef} className="flex ali-center just-btwn" height={40}>
                        <Box className="flex ali-center" zIndex={3000}>
                          <FormControl>
                            <Checkbox checked={todo.done} disabled={disabled} onChange={() => handleChangeCheckbox(i)} />
                          </FormControl>
                          <Box display="flex" flexGrow={1} zIndex={4000}>
                            <TodoTextarea cols={60} maxRows={3} value={todo.title} onChange={(e) => handleChangeTitle(e, i)} />
                          </Box>
                        </Box>
                        <Box className="flex ali-center">
                          <Box {...provided.dragHandleProps} sx={{ mt: 0.5, mr: 1 }}>
                            <DragIndicator sx={{ width: 18 }} />
                          </Box>
                          <Box
                            py={1}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              flexGrow: 0,
                              '&.MuiBox-root': { opacity: 0.2 },
                              '&.MuiBox-root:hover': { opacity: 1 }
                            }}
                          >
                            <IconButton onClick={() => handleDeleteTodo(i)}>
                              <DeleteIcon width={16} />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Draggable>
                ))
              ) : (
                <></>
              )}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

export default TodoList;
