import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

// formattazione date

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const humanizeDate = (treshold: string) => {
  return dayjs(treshold).fromNow();
};
// formattazione nomi

export const initials = (firstname: string, lastname: string) => {
  return firstname.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase();
};

export const avatarInitials = (label?: string) => {
  if (!label) return 'ND';
  if (label) {
    if (label.includes(' ')) {
      const words = label.split(' ');
      return words[0][0] + words[1][0];
    }
    return label.slice(0, 2);
  }
};

export const shortname = (firstname: string, lastname: string) => {
  return firstname.charAt(0).toUpperCase() + '. ' + lastname;
};

export const formatBuildingName = (name: string) => {
  return name?.replace(/[^\w\s]|_|\s/g, '-').toLowerCase() ?? '';
};

export const formatName = (firstname: string, lastname: string) => {
  return `${firstname.replace(' ', '')} ${lastname.replace(' ', '')}`.replace(/[^\w\s]|_|\s/g, '-').toLowerCase();
};

export const formatBackOrder = (name: string) => {
  const words = name.split('-');
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
};

export const normalizeCurrency = (num: string) => {
  return Number(num.replaceAll('€', '').replaceAll('.', '').replaceAll(',', '.'));
};

// formattazione numero di telefono

export const formatPhone = (number: string) => {
  // if (number.startsWith('+')) {
  // }
  if (number.length === 10) {
    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
  } else if (number.startsWith('+')) {
    const sliced = number.slice(1);
    return '+' + sliced.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '$1 $2 $3 $4');
  }
};

// formattazione cifre monetarie

const defaultOptions = {
  significantDigits: 2,
  thousandsSeparator: '.',
  decimalSeparator: ','
};

export const currencyFormatter = (value: any) => {
  const options = { ...defaultOptions };
  if (typeof value !== 'number' && typeof value !== 'string' && !value) {
    return 'Non definito';
  }
  let copy = Number(value);
  let newVal = copy.toFixed(options.significantDigits);
  let stringVal = newVal.toString();

  const [currency, decimal] = stringVal.split('.');
  return `${currency.replace(/\B(?=(\d{3})+(?!\d))/g, options.thousandsSeparator)}${options.decimalSeparator}${decimal}`;
};

export default function formatNumber(num: number, options?: { fractionDigits: number }) {
  return num.toLocaleString('it', {
    minimumFractionDigits: options?.fractionDigits ?? 0,
    maximumFractionDigits: options?.fractionDigits ?? 0
  });
}

export const formatShortNumber = (value: string | number) => {
  const num = typeof value === 'number' ? value : Number(value);
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.findLast((item) => num >= item.value);
  return item ? (num / item.value).toFixed(2).replace(regexp, '').concat(item.symbol) : '0';
};

export const formatPercentual = (value: string) => {
  return Number(value.replace(',', '.'));
};
