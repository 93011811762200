// import { Link } from 'react-router-dom';

// material-ui
import { Button, Grid2, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { APP_DEFAULT_PATH } from 'config';

// project import
// import config from 'config';
// assets

// ==============================|| UNDER CONSTRUCTION - MAIN ||============================== //

function Unauthorized() {
  return (
    <Grid2
      container
      width="100%"
      spacing={4}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: '100vh', py: 2, px: 8 }}
    >
      <Grid2 size={{ xs: 12 }}>
        <Stack spacing={2} className="flex just-center ali-center">
          <Typography align="center" variant="h1">
            Non hai i permessi per visualizzare questa pagina
          </Typography>
          <Typography sx={{ width: '85%', color: 'textSecondary', align: 'center' }}>
            Chiedi ad un amministratore di aggiornare i tuoi permessi.
          </Typography>
        </Stack>
        <Stack className="flex just-center ali-center" spacing={2} sx={{ mt: 3 }}>
          <Button component={Link} to={APP_DEFAULT_PATH} variant="contained">
            Torna alla home
          </Button>
        </Stack>
      </Grid2>
    </Grid2>
  );
}

export default Unauthorized;
