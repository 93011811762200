import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Users from './Users';
import Collaborators from './Collaborators';
import { Box } from '@mui/material';
import Single from './Single';
import Buildings from './Buildings';
import Orders from './Orders';

/* questo componente mostra nell'header l'area di riferimento che si sta visitando */
const HeaderBreadcrumb = () => {
  const { pathname } = useLocation();

  const [paths, setPaths] = useState<string[]>([]);

  // uso come riferimento il path splittato
  useEffect(() => {
    const allParams = pathname.split('/');
    allParams.shift();
    setPaths(allParams);
  }, [pathname]);

  /*
   * controllo che ci sia un determinato path.
   * alcuni path sono prima di altri per evitare falsi positivi ('fast-registration-users' -> 'users')
   *
   * i path che non hanno rotte nestate usano il componente <Single/>
   * i path che hanno rotte nestate utilizzano il loro componente costruito ad hoc
   * inizialmente ho cercato una singola soluzione standardizzata, ma il tempo stringeva
   * la soluzione standardizzata ora c'è ed è TCN_breadcrumbs
   */
  return (
    <Box>
      <Box>{paths.includes('fast-registration-users') && <Single path={paths} />}</Box>
      <Box>{paths.includes('switcho-users') && <Single path={paths} />}</Box>
      <Box>{paths.includes('users') && <Users path={paths} />}</Box>
      <Box>{paths.includes('collaborators') && <Collaborators path={paths} />}</Box>
      <Box>{paths.includes('buildings') && <Buildings path={paths} />}</Box>
      <Box>{paths.includes('orders') && <Orders path={paths} />}</Box>
      <Box>{paths.includes('dashboard') && <Single path={paths} />}</Box>
      <Box>{paths.includes('analytics') && <Single path={paths} />}</Box>
      <Box>{paths.includes('faq') && <Single path={paths} />}</Box>
      <Box>{paths.includes('referral-codes') && <Single path={paths} />}</Box>
      <Box>{paths.includes('settings') && <Single path={paths} />}</Box>
      <Box>{paths.includes('partners') && <Single path={paths} />}</Box>
      <Box>{paths.includes('platform-contracts') && <Single path={paths} />}</Box>
      <Box>{paths.includes('log') && <Single path={paths} />}</Box>
      <Box>{paths.includes('autopilot') && <Single path={paths} />}</Box>
    </Box>
  );
};
export default HeaderBreadcrumb;
