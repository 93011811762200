import { Autocomplete, AutocompleteProps } from '@mui/material';
import { Dispatch, useEffect, useRef, useState } from 'react';
import { ServerListSelector } from 'types/server-list-selector';
import { CountedData } from 'types/counted-data';
import { debounce } from 'lodash';
import { useEndpoint } from 'hooks/useEndpoint';
import { useNavigate } from 'react-router';

type AsyncAutoCompleteProps<T, R = T> = ServerListSelector<T, R> &
  Omit<AutocompleteProps<R, boolean, boolean, boolean>, 'loading' | 'options' | 'onChange'> & {
    onChange?: (
      item: R | NonNullable<string | R> | (string | R)[] | null,
      serverOptions: T[],
      setOptions: Dispatch<React.SetStateAction<R[]>>,
      e: React.SyntheticEvent<Element, Event>
    ) => void;
    onFetchSuccess?: (options: R[]) => void;
    inputChangeFilterField?: string;
    navigateRefetch?: boolean;
  };

export default function AsyncAutoComplete<T, R = T>(props: AsyncAutoCompleteProps<T, R>) {
  const {
    mapServerResponse,
    inputChangeFilterField,
    endpoint,
    query,
    onChange,
    flatMapServerResponse,
    onFetchSuccess,
    navigateRefetch,
    queryOptions,
    ...otherProps
  } = props;
  const [options, setOptions] = useState<R[]>([]);
  const [loading, setLoading] = useState(true);
  const [textValue, setTextValue] = useState<string>('');
  const navigate = useNavigate();
  const autoCompleteValueRef = useRef<R | NonNullable<string | R> | (string | R)[] | null>();

  const fetch = useEndpoint<CountedData<T>, 'get'>({
    method: 'get',
    endpoint,
    queryKey: `get-${endpoint}`,
    queryParams: query
      ? query
      : inputChangeFilterField && textValue.length > 0 && autoCompleteValueRef.current == null
        ? {
            filterBy: JSON.stringify([{ field: inputChangeFilterField, value: { contains: textValue } }])
          }
        : undefined,
    options: {
      enabled: !props.disabled,
      cacheTime: 0,
      ...(queryOptions as any)
    }
  });

  useEffect(() => {
    if (navigateRefetch && !props.disabled) {
      fetch.refetch();
    } else if (!props.disabled) {
      fetch.refetch();
    }
  }, [navigate, props.disabled]);

  useEffect(() => {
    if (fetch.data?.data) {
      setLoading(false);
      if (mapServerResponse) {
        setOptions(fetch.data.data.data.map(mapServerResponse));
        if (onFetchSuccess) {
          onFetchSuccess(fetch.data.data.data.map(mapServerResponse));
        }
      } else if (flatMapServerResponse) {
        setOptions(fetch.data.data.data.flatMap(flatMapServerResponse));
        if (onFetchSuccess) {
          onFetchSuccess(fetch.data.data.data.flatMap(flatMapServerResponse));
        }
      } else {
        setOptions(fetch.data.data.data as unknown as R[]);
        if (onFetchSuccess) {
          onFetchSuccess(fetch.data.data.data as unknown as R[]);
        }
      }
    }
  }, [fetch.data]);

  const handleInputChange = (value: string) => {
    setLoading(true);
    setTextValue(value);
  };
  const debouncedOnChange = debounce(handleInputChange, 200);

  return (
    <Autocomplete<R, boolean, boolean, boolean>
      disablePortal={true}
      onBlur={(event) => {
        if (autoCompleteValueRef.current == null) {
          fetch.refetch();
        }
        if (props.onBlur) props.onBlur(event);
      }}
      options={options}
      loading={loading || fetch.isFetching}
      onChange={
        onChange
          ? (e, i) => {
              autoCompleteValueRef.current = i;
              onChange(i, fetch.data?.data.data ?? [], setOptions, e);
            }
          : undefined
      }
      noOptionsText={'Nessun risultato'}
      loadingText={'Attendi...'}
      onInputChange={(e, value) => debouncedOnChange(value)}
      {...otherProps}
    />
  );
}
