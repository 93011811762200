import { Box, Typography } from '@mui/material';
import { useEndpoint } from 'hooks/useEndpoint';
import { useRefetchQueries } from 'hooks/useRefetchQueries';

interface Props {
  setFilter: React.Dispatch<React.SetStateAction<{ field: string | string[]; value: { equals: null } | { not?: number } }[] | undefined>>;
}

export const ActionsAndFilters = ({ setFilter }: Props) => {
  const { refetchQueries } = useRefetchQueries();

  const readAllNotifications = useEndpoint<undefined, 'put'>({
    method: 'put',
    endpoint: '/notifications/readings',
    mutationKey: 'notifications-readings-all',
    options: {
      onSuccess: () => {
        refetchQueries(['get-all-notifications']);
      }
    }
  });

  return (
    <Box className="flex ali-center just-btwn vertical-standard-margin" width="100%">
      <Box display="flex">
        <Typography variant="body2" className="textSecondary clickable" sx={{ mr: 1 }} onClick={() => setFilter(undefined)}>
          Tutte
        </Typography>
        <Typography
          mx={1}
          variant="body2"
          className="textSecondary clickable"
          onClick={() => setFilter([{ field: 'readAt', value: { equals: null } }])}
        >
          Non lette
        </Typography>
      </Box>
      <Box display="flex" onClick={() => readAllNotifications.mutate(undefined)}>
        <Typography mx={1} variant="body2" className="textSecondary clickable">
          Segna tutte come lette
        </Typography>
      </Box>
    </Box>
  );
};
