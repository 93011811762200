import Navigation from './Navigation';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { CustomSimplebar } from 'components/custom/custom-simplebar';

// ==============================|| DRAWER CONTENT ||============================== //

// contenuto del drawer sinistro

const DrawerContent = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <CustomSimplebar
      style={{
        height: !matchDownSM ? 'calc(100% - 80px)' : 'calc(100% - 60px)',
        borderRight: `1px solid ${theme.palette.divider}`
      }}
    >
      <Navigation />
    </CustomSimplebar>
  );
};

export default DrawerContent;
