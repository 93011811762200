import React, { createContext, useEffect, useMemo } from 'react';
import { Socket, io } from 'socket.io-client';
import * as process from 'process';
import { dispatch, useSelector } from 'store';
import { SocketResponse } from 'types/socket-response';

import { setFetchNotification } from 'store/reducers/helpers';
import { useLocation } from 'react-router';
import { useRefetchQueries } from 'hooks/useRefetchQueries';

export const WebsocketContext = createContext<Socket | null>(null);

let refreshCount = 0;
export const WebsocketProvider = ({ children }: { children: React.ReactElement }) => {
  const { rememberMe } = useSelector((state) => state.authorization);
  const location = useLocation();
  const { refetchQueries, queryPresets } = useRefetchQueries();

  const socket = useMemo(
    () =>
      io(process.env.REACT_APP_SERVER_URL, {
        path: '/tcnwbsckt',
        transports: ['websocket', 'polling'],
        auth: {
          token: localStorage['stayActive'] === 'true' ? localStorage['accessToken'] : sessionStorage['accessToken']
        },
        extraHeaders: {
          Authorization: `Bearer ${localStorage['stayActive'] === 'true' ? localStorage['accessToken'] : sessionStorage['accessToken']}`
        },
        reconnection: false,
        autoConnect: true
      }),
    []
  );

  useEffect(() => {
    if (!socket.connected && refreshCount <= 3) {
      socket.connect();
    }
  }, [location]);

  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }

    // alla connessione del websocket viene resettato il counter massimo di tentativi refreshCount
    socket.on('connect', () => {
      console.log('websocket connesso');
      refreshCount = 0;
    });

    // all'errore di connessione si _dovrebbe_ tentare per un massimo di 3 volte
    // altrimenti viene chiusa la connessione
    socket.on('connect_error', async (err) => {
      console.log('Connection websocket failed', err);
      if (refreshCount <= 3) {
        refreshCount += 1;
        //@ts-ignore
        socket.auth.token = rememberMe ? localStorage['accessToken'] : sessionStorage['accessToken'];
        socket.connect();
      } else {
        socket.close();
      }
    });

    socket.on('disconnect', () => {
      console.log('websocket disconnesso');
    });

    /*
     *  * nota *
     *          gli eventi legati a dati dell'app fanno riferimento a delle risorse
     *          quindi l'evento avrà sempre il prefisso resource/
     *          le notifiche invece sono a parte (notifications)
     */

    // se vengo notificato dal socket sui task
    socket.on('resource/task', (data) => {
      // aggiornamenti task e conferme di lettura
      refetchQueries(queryPresets.tasks);
    });

    // nel data dei socket viene specificato l'environment (data.env)
    // 'api' è l'app utente, 'server' è il backoffice

    // ad un qualsiasi evento emesso sul preventivo d'ordine
    socket.on('resource/orderQuote', (data) => {
      // da parte dell'utente
      if (data.env === 'api') {
        // aggiorna il dato dell'ordine corrispondente
        // è un array per una questione di possibile condizione di concorrenza
        // tra diverse richieste, e in generale, per le altre risorse potrebbero esserci
        // più soggetti
        refetchQueries(data.subjects.map((s: string) => `get-order-${s}`));
      }
    });

    // su un evento scaturito dalla risorsa note
    socket.on('resource/note', (data) => {
      // aggiorna le note per il backoffice
      refetchQueries(['get-all-notes']);
    });

    // su un evento scaturito dalla risorsa ordini
    socket.on('resource/order', (data) => {
      // aggiorna tutti gli ordini corrispondenti
      refetchQueries(data.subjects.map((s: string) => `get-order-${s}`));
    });

    // su un evento di notifica
    socket.on('notifications', (data: SocketResponse) => {
      // aggiorna tutte le notifiche
      refetchQueries(['get-all-notifications']);
      // inserisce nello store la singola notifica di cui fare la get dal server
      dispatch(setFetchNotification({ id: data.id, env: data.env, userId: data.userId }));
    });

    // funzione di cleanup
    return () => {
      socket.off('connect');
      socket.off('connect_error');
      socket.off('notifications');
      socket.disconnect();
      socket.off('disconnect');
    };
  }, []);

  return <WebsocketContext.Provider value={socket}>{children}</WebsocketContext.Provider>;
};
