import { queryClient } from 'App';

export const useRefetchQueries = () => {
  // questi preset vengono usati per non ripetere le chiavi continuamente sui refetch
  const queryPresets = {
    buildings: {
      single: (id: string | number) => [`get-building-${id}`],
      multiple: ['get-buildings']
    },
    wallet: (id: string | number) => [`get-building-${id}-wallet`],
    tasks: ['get-all-tasks', 'get-tasks-readings'],
    fluxes: {
      single: (id: string) => [`get-flux-${id}`]
    },
    users: {
      single: (id: string | number) => [`get-user-${id}`],
      multiple: { clients: ['get-users'], backoffice: ['get-all-backoffice-users'], allPlatforms: ['get-all-platform-users'] }
    },
    documents: {
      multiple: ['get-document-auto-requests']
    },
    orders: {
      single: (id: string) => [`get-order-${id}`],
      multiple: ['get-all-orders']
    },
    partners: {
      multiple: ['get-partners']
    },
    referrals: {
      multiple: ['get-all-referral-codes']
    }
  };
  const refetchQueries = (queries: string[]) => {
    for (let query of queries) {
      queryClient.refetchQueries({ queryKey: [query] });
    }
  };
  return { refetchQueries, queryPresets };
};
