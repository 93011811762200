import { FormattedMessage } from 'react-intl';

// assets
import ReferralCodeLogo from 'assets/images/icons/navigation/Referral';

// type
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const referralCodes: NavItemType = {
  id: 'referralCodes',
  title: '',
  type: 'group',
  children: [
    {
      id: 'referralCodes',
      //@ts-ignore
      title: <FormattedMessage id="referral-codes" />,
      type: 'item',
      icon: ReferralCodeLogo,
      permissions: [{ resource: null, operations: ['read'] }],
      url: '/referral-codes'
    }
  ]
};

export default referralCodes;
