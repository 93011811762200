import { Box, Button, Typography } from '@mui/material';
import useModal from 'hooks/useModal';

interface Props {
  confirmationText: string | React.ReactNode;
  onDismissCallback?: () => void;
  onConfirmCallback?: () => void;
}

export const AskConfirmation = ({ confirmationText, onDismissCallback, onConfirmCallback }: Props) => {
  const { closeModal } = useModal();

  return (
    <Box className="modal-container">
      {typeof confirmationText === 'string' ? <Typography>{confirmationText}</Typography> : confirmationText}
      <Box className="flex just-end" sx={{ mt: 4 }}>
        <Button
          sx={{ mr: 1 }}
          onClick={async () => {
            if (onDismissCallback) {
              await onDismissCallback();
            }
            closeModal();
          }}
        >
          annulla
        </Button>

        <Button
          variant="contained"
          onClick={async () => {
            if (onConfirmCallback) {
              await onConfirmCallback();
            }
            closeModal();
          }}
        >
          conferma
        </Button>
      </Box>
    </Box>
  );
};
