import { CloseOutlined } from '@mui/icons-material';
import { Box, FormControl, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { resourceObjectSelector } from 'utils/filtersUtils/resourceSelector';
import { endpointReturn } from 'utils/selectEndpoint/endpointSetter';
import { SectionSelector } from '../../shared-components/section-selector';
import { SubjectFilter } from 'components/custom/async-selectors/subject-filter';
import { SectionFilterProps, SetSectionFilterProps, SetSubjectProps, SubjectProps } from '../../types/tasks';
import { dispatch } from 'store';
import { setRightDrawerOpen } from 'store/reducers/menu';

interface Props {
  resource?: SectionFilterProps;
  setResource: SetSectionFilterProps;
  subject?: SubjectProps;
  setSubject: SetSubjectProps;
}
const Header = ({ resource, setResource, subject, setSubject }: Props) => {
  const { pathname } = useLocation();
  const endpoint = endpointReturn(resource);

  useEffect(() => {
    setResource(resourceObjectSelector(pathname)?.slug);
  }, [pathname]);

  return (
    <Box className="right-drawer-header">
      <Box className="header-container">
        <Box className="flex ali-center">
          <CloseOutlined
            className="clickable"
            style={{ fontSize: 22, marginRight: 14, color: '#9aa4a7' }}
            onClick={() => {
              dispatch(setRightDrawerOpen(false));
            }}
          />
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            Note
          </Typography>
        </Box>
        <Box className="flex ali-center">
          <SectionSelector section={resource} setSection={setResource} setSubject={setSubject} />
          <FormControl
            sx={{
              width: 230
            }}
          >
            <SubjectFilter key="tasks-subject" mt={0} endpoint={endpoint} subject={subject} setSubject={setSubject} />
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
