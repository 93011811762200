import { Box } from '@mui/material';
import { useSelector } from 'store';

export const Backdrop = () => {
  const { rightDrawerOpen } = useSelector((state) => state.menu);
  return (
    <Box
      sx={{
        display: rightDrawerOpen ? 'block' : 'none',
        position: 'fixed',
        left: 0,
        width: '100%',
        height: 'calc(100vh - 74px)',
        zIndex: 1000,
        backgroundColor: '#041C2280'
      }}
    />
  );
};
