import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';

// dopo due ore di inattività mostra il componente Dialog
const Idle = () => {
  const [open, setOpen] = useState(false);
  useIdleTimer({
    timeout: 7200000,
    onIdle: () => {
      setOpen(true);
    }
  });
  return (
    <Dialog open={open} sx={{ zIndex: 3000 }}>
      <DialogTitle>Sei ancora connesso?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          La tua finestra è aperta da oltre 2 ore, ricarica la pagina per ricevere i nuovi aggiornamenti.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Annulla</Button>
        <Button onClick={() => window.location.reload()}>Ricarica</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Idle;
