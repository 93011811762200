// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M145.605 249.919H104.396V215.579C104.396 176.955 72.9639 145.524 34.3407 145.524H0V104.315H34.3407C95.6691 104.315 145.605 154.25 145.605 215.579V249.919Z"
        fill="#094754"
      />
      <path
        d="M250 145.605H215.659C154.331 145.605 104.396 95.6691 104.396 34.3407V0H145.604V34.3407C145.604 72.9639 177.036 104.396 215.659 104.396H250V145.605Z"
        fill="#094754"
      />
    </svg>
  );
};

export default LogoIcon;
