import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeSnackbar, openSnackbar } from 'store/reducers/snackbar';

const Offline = () => {
  const dispatch = useDispatch();
  // stato online del browser, preso da navigator
  const [isBrowserOnline, setIsBrowserOnline] = useState(navigator.onLine);
  const [open, setOpen] = useState(false);

  // Update network status
  const handleStatusChange = () => {
    setIsBrowserOnline(navigator.onLine);
  };

  // potrebbe essere sostituito da uno useMemo
  useEffect(() => {
    // se non è online, mostra uno snackbar
    if (!isBrowserOnline) {
      setOpen(true);
      dispatch(
        openSnackbar({
          open: true,
          message: 'Il tuo dispositivo è offline.',
          variant: 'error',
          persist: true,
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top'
          },
          key: 'Offline'
        })
      );
    } else if (isBrowserOnline && open) {
      dispatch(closeSnackbar('Offline'));
      setOpen(false);
    }

    // ascolta lo status online del navigator
    window.addEventListener('online', handleStatusChange);

    // ascolta lo stato offline del navigator
    window.addEventListener('offline', handleStatusChange);

    // funzione di cleanup per non aggiungere eventListener uno sull'altro
    // a ogni cambio di "isBrowserOnline"
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isBrowserOnline]);
  return <></>;
};

export default Offline;
