import { Box } from '@mui/material';
import { styled } from '@mui/material';

export const FadeBox = styled(Box)(() => ({
  opacity: 1,
  animation: 'fade .3s linear',
  '@keyframes fade': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  }
}));
