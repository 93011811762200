import { Box, useTheme } from '@mui/material';
import Logo from 'components/logo';

interface Props {
  open: boolean;
}

const DrawerHeader = ({ open }: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '74px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 0,
        borderTop: process.env.NODE_ENV === 'production' ? '4px solid #F0FF32' : '4px solid #C8D600',
        backgroundColor: process.env.NODE_ENV === 'production' ? 'background.paper' : 'secondary.light',
        transition: `all ${open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp} 0ms!important`
      }}
    >
      <Logo isIcon={!open} />
    </Box>
  );
};

export default DrawerHeader;
