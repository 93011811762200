import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './login-routes';
import ProtectedRoutes from './protected-routes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  // le rotte sono divise in rotte pubbliche e rotte private (LoginRoutes | ProtectedRoutes)
  return useRoutes([LoginRoutes, ProtectedRoutes]);
}
