import { Box } from '@mui/material';
import { NewUploadsList } from './new-upload-list';
import { UploadsList } from './upload-list';
import DragDropUpload from 'components/custom/drag-drop-upload/drag-drop-upload';
import { taskDocumentsFormat } from 'utils/fileFormats';
import { useEffect, useState } from 'react';
import { FilesData } from 'types/FilesData';
import { UploadDTO } from 'types/dto/upload.dto';
import { useEndpoint } from 'hooks/useEndpoint';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { SendTaskDTO } from 'types/dto/task.dto';
import { FormikErrors } from 'formik';
import { useRefetchQueries } from 'hooks/useRefetchQueries';

interface Props {
  taskID?: number;
  isNewTask: boolean;
  uploads?: UploadDTO[];
  lastEdit?: string;
  setValues: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) =>
    | Promise<void>
    | Promise<
        FormikErrors<{
          data: SendTaskDTO | undefined;
        }>
      >;
}
export const Documents = ({ taskID, isNewTask, uploads, lastEdit, setValues }: Props) => {
  const { refetchQueries } = useRefetchQueries();

  const [filesData, setFilesData] = useState<FilesData>();

  const editTask = useEndpoint<{ uploads: File[]; lastEdit: string }, 'patch'>({
    method: 'patch',
    endpoint: `/tasks/${taskID}`,
    mutationKey: `edit-task-${taskID}`,
    options: {
      onSuccess: () => {
        refetchQueries([`get-task-${taskID}`]);
        dispatch(
          openSnackbar({
            message: `File caricato con successo.`,
            open: true,
            variant: 'success',
            key: `add-document-to-task-${taskID}`
          })
        );
        setFilesData(undefined);
      }
    }
  });

  useEffect(() => {
    if (filesData) {
      if (isNewTask) {
        setValues('data.uploads', filesData.files);
        setFilesData(undefined);
        return;
      }
      editTask.mutate({ uploads: filesData.files, lastEdit: lastEdit! });
    }
  }, [filesData]);

  return (
    <>
      {isNewTask ? (
        <NewUploadsList uploads={uploads as unknown as File[]} setValues={setValues} />
      ) : (
        <UploadsList taskID={taskID!} uploads={uploads!} />
      )}
      <Box sx={{ mt: uploads && uploads.length ? 2 : 0 }}>
        <DragDropUpload
          i={1}
          multiple={true}
          buttonSize="small"
          title="carica documento"
          type={uploads && uploads.length ? 'button' : 'drag'}
          fileFormats={taskDocumentsFormat}
          filesData={filesData}
          setFilesData={setFilesData}
        />
      </Box>
    </>
  );
};
