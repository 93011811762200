import { forwardRef, useEffect, ForwardRefExoticComponent, RefAttributes, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import { Box, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project import
import { activeItem } from 'store/reducers/menu';

// types
import { LinkTarget, NavItemType } from 'types/menu';
import { RootStateProps } from 'types/root';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

interface Props {
  item: NavItemType;
  level: number;
}

const NavItem = ({ item, level }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen, openItem } = menu;

  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps: {
    component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
    href?: string;
    target?: LinkTarget;
  } = { component: forwardRef((props, ref) => <Link {...props} to={item.url!} target={itemTarget} />) };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const Icon = item.icon!;
  const itemIcon = item.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : false;

  const { pathname } = useLocation();
  const [isSelected, setIsSelected] = useState(false);

  // al cambio di pathname setta il menu item attivo
  useEffect(() => {
    if (pathname === item.url) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
  }, [pathname]);

  // idem, da controllare a cosa serve e se necessario
  // magari sostituire la logica degli useEffect
  useEffect(() => {
    setIsSelected(openItem.findIndex((id) => id === item.id) > -1);
  }, [openItem]);

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        pl: drawerOpen && !matchDownSM ? 2.8 : 1.5,
        py: !drawerOpen && level === 1 && !matchDownSM ? 1.25 : 1,
        ...(drawerOpen && {
          '&:hover': {
            bgcolor: alpha(theme.palette.secondary.light, 0.35)
          },
          '&.Mui-selected': {
            bgcolor: theme.palette.secondary.light,
            '&:hover': {
              bgcolor: theme.palette.secondary.light
            }
          }
        }),
        ...(!drawerOpen && {
          '&:hover': {
            bgcolor: alpha(theme.palette.secondary.light, 0.35)
          },
          '&.Mui-selected': {
            '&:hover': {
              bgcolor: theme.palette.secondary.light
            },
            bgcolor: theme.palette.secondary.light
          }
        })
      }}
    >
      <Box className="flex ali-center" flexGrow={1} sx={{ pl: itemIcon ? 0 : drawerOpen ? '28px' : 0 }}>
        {itemIcon && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              ...(!drawerOpen &&
                !matchDownSM && {
                  borderRadius: 1.5,
                  width: 36,
                  height: 36,
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter'
                  }
                }),
              ...(!drawerOpen &&
                isSelected && {
                  bgcolor: theme.palette.mode === 'dark' ? 'primary.900' : 'primary.lighter',
                  '&:hover': {
                    bgcolor: theme.palette.mode === 'dark' ? 'primary.darker' : 'primary.lighter'
                  }
                })
            }}
          >
            {itemIcon}
          </ListItemIcon>
        )}
        {/* se il menù è aperto mostro il nome */}
        {(drawerOpen || (!drawerOpen && level !== 1) || matchDownSM) && (
          <ListItemText primary={<Typography sx={{ color: theme.palette.primary.main, ml: 3 }}>{item.title}</Typography>} />
        )}
      </Box>
    </ListItemButton>
  );
};

export default NavItem;
