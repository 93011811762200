import { Box, Pagination } from '@mui/material';
import Header from './Header';
import { NoteAndSearch } from './note-and-search';
import NoNotes from './no-notes';
import NotesLoading from 'components/custom/loading/right-drawer/notes';
import NoteList from './note-list';
import useLoader from 'hooks/useLoader';
import { CountedNotes, SetSearchFilterProps } from '../../types/notes';
import { SectionFilterProps, SetPaginationProps, SetSectionFilterProps, SetSubjectProps, SubjectProps } from '../../types/tasks';

interface Props {
  notes?: CountedNotes;
  subject: SubjectProps;
  setSubject: SetSubjectProps;
  resource?: SectionFilterProps;
  setResource: SetSectionFilterProps;
  pageSize: number;
  setPagination: SetPaginationProps;
  setSearchFilter: SetSearchFilterProps;
}
export const Note = ({ subject, setSubject, resource, setResource, pageSize, setPagination, notes, setSearchFilter }: Props) => {
  const { isLoading } = useLoader();

  if (notes && notes.data.length === 0) {
    return <NoNotes />;
  }
  if (!notes || isLoading) {
    return <NotesLoading pageSize={pageSize} />;
  }
  return (
    <Box className="right-drawer-el-container">
      <Header resource={resource} setResource={setResource} subject={subject} setSubject={setSubject} />
      <Box className="standard-margin-container">
        <NoteAndSearch subject={subject} section={resource} setSearchFilter={setSearchFilter} />
        <Box
          className={`${
            subject.label.length ? 'edited-notes-height' : 'standard-notes-height'
          } vertical-standard-margin flex dir-col just-btwn`}
        >
          <NoteList notes={notes.data} />
          <Pagination
            onChange={(e, v) => setPagination({ pageIndex: v - 1, pageSize: 6 })}
            count={notes.filteredCount && Math.ceil(notes.filteredCount / 6)}
            size="large"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
              pb: 2,
              '.MuiPaginationItem-root': { position: 'relative', zIndex: 1 }
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
