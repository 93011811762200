import { Avatar, Chip, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { setActiveRightItem, setRightDrawerOpen } from 'store/reducers/menu';

const Note = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { notesCount } = useSelector((state) => state.helpers);
  const { activeRightItem, rightDrawerOpen } = useSelector((state) => state.menu);

  /*  funzione per il cambio di sezione tra task-note-notifiche
   *   due click consecutivi su una sezione chiudono il drawer
   */
  const handleRightDrawerToggle = () => {
    if (activeRightItem === 'note' && rightDrawerOpen) {
      dispatch(setRightDrawerOpen(false));
    } else {
      dispatch(setActiveRightItem('note'));
      dispatch(setRightDrawerOpen(true));
    }
  };

  // qui non c'è il badge perchè non c'è conferma di lettura delle note
  return (
    <Chip
      onClick={handleRightDrawerToggle}
      label="Note"
      avatar={<Avatar sx={{ bgcolor: `${theme.palette.primary.main}` }}>{notesCount}</Avatar>}
      size="large"
      sx={{
        mr: 3,
        cursor: 'pointer',
        '& .MuiChip-label': {
          fontWeight: '700',
          fontSize: '13px'
        },
        bgcolor: `${theme.palette.action.hover}`
      }}
    />
  );
};

export default Note;
