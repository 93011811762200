import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useEndpoint } from 'hooks/useEndpoint';
import { CountedData } from 'types/counted-data';
import { ResourceDTO } from 'types/dto/resource.dto';
import { SectionFilterProps, SetSectionFilterProps, SetStateFilterProps, SetSubjectProps } from '../types/tasks';

interface Props {
  section?: SectionFilterProps;
  setSection: SetSectionFilterProps;
  setSubject?: SetSubjectProps;
  setStateFilter?: SetStateFilterProps;
}
export const SectionSelector = ({ section, setSection, setSubject, setStateFilter }: Props) => {
  const getResources = useEndpoint<CountedData<ResourceDTO>, 'get'>({
    method: 'get',
    endpoint: '/tasks/resources',
    queryKey: 'get-tasks-resources'
  });
  const resources = getResources.data?.data.data;

  const getTasksReadings = useEndpoint<{ data: string[] }, 'get'>({
    method: 'get',
    endpoint: '/tasks/readings',
    queryKey: 'get-tasks-readings'
  });
  const tasksReadings = getTasksReadings.data?.data.data;

  return (
    <FormControl size="small" sx={{ width: 230, mr: 2 }}>
      <InputLabel id="resource-selector">Sezione</InputLabel>
      <Select
        labelId="resource-selector"
        id="resource-selector"
        size="small"
        value={section ? section : 'null'}
        onChange={(e: SelectChangeEvent<string>) => {
          setSection(e.target.value === 'null' ? JSON.parse(e.target.value) : e.target.value);
          setSubject && setSubject({ serverData: [], label: '' });
          setStateFilter && setStateFilter(undefined);
        }}
        label="Sezione"
        MenuProps={{ sx: { zIndex: 1700 } }}
      >
        <MenuItem value={'all'} className="flex ali-center just-btwn">
          <Box className="flex ali-center just-btwn" width={'100%'}>
            <Typography>Tutti</Typography>

            {tasksReadings?.length ? (
              <Box width={8} height={8} sx={{ backgroundColor: 'error.main', borderRadius: '100%', mr: 1 }} />
            ) : (
              <></>
            )}
          </Box>
        </MenuItem>

        <MenuItem value={'null'} className="flex ali-center just-btwn">
          <Box className="flex ali-center just-btwn" width={'100%'}>
            <Typography>Generale</Typography>

            {tasksReadings?.includes('general') ? (
              <Box width={8} height={8} sx={{ backgroundColor: 'error.main', borderRadius: '100%', mr: 1 }} />
            ) : (
              <></>
            )}
          </Box>
        </MenuItem>
        {resources &&
          resources.map((r, i) => (
            <MenuItem key={`menu-item-${i}`} value={r.slug} className="flex ali-center just-btwn">
              <Box className="flex ali-center just-btwn" width={'100%'}>
                <Typography>{r.name}</Typography>
                {tasksReadings?.includes(r.slug) ? (
                  <Box width={8} height={8} sx={{ backgroundColor: 'error.main', borderRadius: '100%', mr: 1 }} />
                ) : (
                  <></>
                )}
              </Box>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
