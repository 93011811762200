import { SvgIcon, SvgIconTypeMap } from '@mui/material';

const OrdersLogo = (props: Pick<SvgIconTypeMap['props'], 'sx' | 'fontSize'>) => (
  <SvgIcon sx={props.sx} fontSize={props.fontSize ?? 'medium'}>
    <svg id="Livello_2" data-name="Livello 2" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 23 24">
      <g id="Livello_1-2" data-name="Livello 1">
        <g>
          <polygon fill="#91dcf9" points="10.97 6.66 2.27 9.03 2.27 21.4 10.97 19.03 10.97 6.66" />
          <polygon fill="#91dcf9" points="10.96 6.66 19.65 9.03 19.65 21.4 10.96 19.03 10.96 6.66" />
          <g>
            <path
              fill="#f0ff32"
              d="m17.79,6.89s0-.04,0-.07c0-.03,0-.06,0-.1,0-.03,0-.06,0-.08,0-.02,0-.05,0-.07,0-.03,0-.06,0-.1,0-.02,0-.04,0-.06,0-.04,0-.07-.01-.11,0-.01,0-.03,0-.04,0-.04,0-.08-.01-.12,0,0,0-.01,0-.02-.01-.08-.02-.16-.04-.23,0-.01,0-.03,0-.04,0-.02,0-.03,0-.05,0-.04-.02-.09-.02-.13,0,0,0,0,0,0-.06-.31-.15-.61-.24-.9h0C16.47,2.13,13.94.23,10.96.23s-5.5,1.9-6.44,4.55h0c-.1.29-.18.59-.24.9,0,0,0,0,0,0,0,.04-.02.09-.02.13,0,.02,0,.03,0,.05,0,.01,0,.03,0,.04-.01.08-.03.16-.04.23,0,0,0,.01,0,.02,0,.04,0,.08-.01.12,0,.01,0,.03,0,.04,0,.04,0,.07-.01.11,0,.02,0,.04,0,.06,0,.03,0,.06,0,.1,0,.02,0,.05,0,.07,0,.03,0,.06,0,.08,0,.03,0,.06,0,.1,0,.02,0,.04,0,.07,0,.05,0,.11,0,.17,0,3.8,3.04,6.83,6.83,6.83s6.83-3.04,6.83-6.83c0-.06,0-.11,0-.17h0Z"
            />
            <path
              fill="#094754"
              d="m10.96,14.12c-3.89,0-7.06-3.17-7.06-7.06v-.24s.24-.09.24-.09h-.23s.05-.61.05-.61c.01-.1.03-.18.04-.26l.05-.25c.06-.32.14-.61.24-.9l.07-.15C5.4,1.82,8.03,0,10.96,0s5.56,1.82,6.6,4.55h0l.06.15c.08.24.15.49.21.74h.04l.04.42c.01.07.02.15.04.23l.06.95c0,3.89-3.17,7.06-7.06,7.06ZM4.48,5.78v.07s-.25.03-.25.03l.22.04c-.02.1-.03.17-.04.25l-.06.89c0,3.64,2.96,6.61,6.61,6.61s6.6-2.96,6.6-6.61l-.04-.69.22-.1-.22.03v-.12c-.03-.09-.04-.17-.05-.24l.07-.03-.08-.03-.03-.15c-.05-.26-.12-.51-.2-.76l-.04-.1c-.93-2.63-3.44-4.4-6.23-4.4s-5.3,1.77-6.23,4.4l-.06.15c-.07.24-.14.48-.18.72v.05Zm13.17.06h0s.03,0,.03,0h-.03Z"
            />
          </g>
          <polygon fill="#094754" points="10.98 24 2.28 21.63 2.28 9.26 10.98 11.63 10.98 24" />
          <polygon fill="#094754" points="10.97 24 19.67 21.63 19.67 9.26 10.97 11.63 10.97 24" />
          <polygon fill="#094754" points="10.98 24 2.28 21.63 2.28 9.26 10.98 11.63 10.98 24" />
          <polygon fill="#094754" points="10.97 24 19.67 21.63 19.67 9.26 10.97 11.63 10.97 24" />
          <polygon fill="#1ed367" points="9.97 17.79 0 14.73 2.28 9.26 10.97 11.63 9.97 17.79" />
          <polygon fill="#1ed367" points="11.97 17.79 21.99 14.43 19.66 9.26 10.97 11.63 11.97 17.79" />
          <polygon fill="#91dcf9" points="2.27 15.41 9.97 17.79 10.96 11.63 2.27 9.26 2.27 15.41" />
          <polygon fill="#91dcf9" points="10.96 11.63 11.97 17.79 19.66 15.18 19.66 9.26 19.65 9.26 10.96 11.63" />
          <rect fill="#094754" x="7.31" y="3.83" width="7.31" height="1.67" />
          <rect fill="#094754" x="7.29" y="6.13" width="7.31" height="1.67" />
          <rect fill="#094754" x="7.31" y="8.42" width="7.31" height="1.67" />
        </g>
      </g>
    </svg>
  </SvgIcon>
);

export default OrdersLogo;
