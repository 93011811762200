import { Box, ClickAwayListener, Typography } from '@mui/material';
import StatusFilter from './status-filter';
import OrderSelect from 'components/custom/order-select';
import { useEndpoint } from 'hooks/useEndpoint';
import { useState } from 'react';
import { useRefetchQueries } from 'hooks/useRefetchQueries';
import { SetShowUnreadProps, SetSortingProps, SetStateFilterProps, ShowUnreadProps } from 'layout/MainLayout/right-drawer/types/tasks';

interface Props {
  sorting: string;
  setSorting: SetSortingProps;
  setStateFilter: SetStateFilterProps;
  showUnread: ShowUnreadProps;
  setShowUnread: SetShowUnreadProps;
}
export const TasksFilters = ({ sorting, setSorting, setStateFilter, showUnread, setShowUnread }: Props) => {
  const { refetchQueries, queryPresets } = useRefetchQueries();

  const [showStatusMenu, setShowStatusMenu] = useState<boolean>(false);

  const readAllTasks = useEndpoint<undefined, 'put'>({
    method: 'put',
    endpoint: '/tasks/readings/all',
    mutationKey: 'read-all-tasks',
    options: {
      onSuccess: () => {
        refetchQueries(queryPresets.tasks);
      }
    }
  });

  const showAll = () => {
    setShowUnread(undefined);
    setSorting('{ "field": "lastEdit", "sort": "desc" }');
  };

  const showOnlyUnread = () => {
    setShowUnread({ field: 'lastReadAt', value: { equals: null } });
    setStateFilter(undefined);
  };

  return (
    <Box mx="auto" className="flex just-btwn ali-center vertical-standard-margin">
      <Box display="flex">
        <Typography
          variant="body2"
          className="textSecondary clickable"
          sx={{ mr: 1 }}
          onClick={() => {
            readAllTasks.mutate(undefined);
          }}
        >
          Segna come letti
        </Typography>
        {showUnread ? (
          <Typography variant="body2" className="textSecondary clickable" sx={{ ml: 2 }} onClick={showAll}>
            Mostra tutti
          </Typography>
        ) : (
          <Typography variant="body2" className="textSecondary clickable" sx={{ ml: 2 }} onClick={showOnlyUnread}>
            Mostra non letti
          </Typography>
        )}
      </Box>
      <Box className="flex ali-center" sx={{ mr: 1, mb: 0.5 }}>
        <ClickAwayListener onClickAway={() => setShowStatusMenu(false)}>
          <Box position="relative">
            <Typography variant="body2" className="textSecondary clickable" sx={{ mt: 0.5, mr: 3 }} onClick={() => setShowStatusMenu(true)}>
              Status
            </Typography>

            <StatusFilter statusOpen={showStatusMenu} setStatusOpen={setShowStatusMenu} setStateFilter={setStateFilter} />
          </Box>
        </ClickAwayListener>
        <Box>
          <OrderSelect sorting={sorting} setSorting={setSorting} filters={['created', 'expire', 'update']} />
        </Box>
      </Box>
    </Box>
  );
};
