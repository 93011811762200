// export default NavGroup;
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, List, Typography } from '@mui/material';

// project import
import NavItem from './nav-item';
import NavCollapse from './nav-collapse';

// types
import { NavItemType } from 'types/menu';
import { RootStateProps } from 'types/root';
import { usePermissions } from 'hooks/usePermissions';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

interface Props {
  item: NavItemType;
}

const NavGroup = ({ item }: Props) => {
  const theme = useTheme();
  const { checkPermission } = usePermissions();
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;

  // qui viene gestito la tipologia di voce di menù
  // può essere un gruppo di voci (NavCollapse)
  // può essere una voce singola (NavItem)
  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      // esistono solo due casi, gruppo ('collapse') e voce singola ('item')
      case 'collapse':
        // se il menuItem possiede dei permessi allora li controllo
        // se non li possiede è una voce "pubblica", non servono permessi e quindi
        // ritorno direttamente la voce evitando il controllo
        return menuItem.permissions ? (
          checkPermission(menuItem.permissions) ? (
            <NavCollapse key={menuItem.id} menu={menuItem} level={1} />
          ) : (
            false
          )
        ) : (
          <NavCollapse key={menuItem.id} menu={menuItem} level={1} />
        );
      case 'item':
        // se il menuItem possiede dei permessi allora li controllo
        // se non li possiede è una voce "pubblica", non servono permessi e quindi
        // ritorno direttamente la voce evitando il controllo
        return menuItem.permissions ? (
          checkPermission(menuItem.permissions) ? (
            <NavItem key={menuItem.id} item={menuItem} level={1} />
          ) : (
            false
          )
        ) : (
          <NavItem key={menuItem.id} item={menuItem} level={1} />
        );

      default:
        return (
          <Typography key={menuItem.id} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  // componente lista dove vengono montati gli elementi
  return (
    <List
      subheader={
        item.title &&
        drawerOpen && (
          <Box sx={{ pl: 3, mb: 1.5 }}>
            <Typography variant="subtitle2" color={theme.palette.mode === 'dark' ? 'textSecondary' : 'text.secondary'}>
              {item.title}
            </Typography>
            {item.caption && (
              <Typography variant="caption" color="secondary">
                {item.caption}
              </Typography>
            )}
          </Box>
        )
      }
      sx={{ mt: drawerOpen && item.title ? 1.5 : 0, py: 0, zIndex: 0 }}
    >
      {navCollapse}
    </List>
  );
};

export default NavGroup;
