import { ErrorOutline } from '@mui/icons-material';
import { Box, FormControl, IconButton, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { AskConfirmation } from 'components/custom/ask-confirmation';
import { CustomTooltip } from 'components/custom/custom-tooltip';
import useModal from 'hooks/useModal';
import { StatusViewType } from 'types/custom/status';
import { OrderState } from 'types/dto/status.dto';
import { translateOrderStatus } from 'utils/discriminators';

interface Props {
  id?: number | string;
  sendData: ({ state, id }: { state: string; id?: number | string }) => void;
  initialStatus: string;
  isProduct?: boolean;
  statusList: StatusViewType[];
  disabled?: boolean;
  orderCode?: string;
  chipSize?: 'small' | 'medium';
}
export const ColoredInstantSelectStatus = ({
  initialStatus,
  chipSize,
  sendData,
  id,
  statusList,
  isProduct,
  disabled,
  orderCode
}: Props) => {
  const { openModal } = useModal();
  const style = (activeStatus: string, statusList: StatusViewType[]): Omit<StatusViewType, 'state' | 'translation'> | null => {
    let selectedStyle = null;
    for (let i = 0; i < statusList.length; i++) {
      if (statusList[i].state === activeStatus) {
        selectedStyle = { color: statusList[i].color, backgroundColor: statusList[i].backgroundColor };
        break;
      }
    }
    return selectedStyle;
  };
  const selectedStatusStyle = style(initialStatus, statusList);

  const handleChange = (e: SelectChangeEvent) => {
    sendData({ state: e.target.value, id });
  };

  const handleOrderChange = (state: OrderState) => {
    sendData({ state: state, id });
  };

  return (
    <FormControl onClick={(e) => e.stopPropagation()}>
      {statusList[0].backofficeTranslation ? (
        <Select
          variant="standard"
          labelId="transcription-status-label"
          id="transcription-status-select"
          disableUnderline
          value={initialStatus}
          sx={{
            '.MuiSelect-standard': selectedStatusStyle,
            '.MuiSelect-standard:focus': selectedStatusStyle,
            '.MuiInput-input': { px: 2, py: 0.5, borderRadius: '20px' },
            '.MuiInput-input:focus': { px: 2, pr: 3, py: 0.5, borderRadius: '20px' },
            '.MuiPopover-paper': { borderRadius: '20px!important' }
          }}
          onChange={handleChange}
          renderValue={(value) =>
            statusList[0].backofficeTranslation ? (
              <Box
                sx={{
                  minWidth: '100px',
                  textAlign: 'center',
                  px: 2,
                  py: 0.5,
                  borderRadius: '20px'
                }}
              >
                <Typography variant="body1">{translateOrderStatus(value as OrderState, isProduct!)}</Typography>
              </Box>
            ) : (
              value
            )
          }
        >
          <Box>
            {statusList[0].backofficeTranslation ? (
              <Box className="flex" py={2}>
                <Box width={300} className="flex dir-col ali-center" position="relative">
                  {disabled && (
                    <CustomTooltip
                      content={
                        initialStatus === OrderState.Draft
                          ? "L'ordine è in bozza, il suo stato non può essere modificato"
                          : "Stiamo aspettando l'azione del cliente, lo status non può essere modificato"
                      }
                      followCursor
                    >
                      <Box position="absolute" top={-30} width="100%" height="108.7%" sx={{ backgroundColor: '#D9D9D93D', zIndex: 1 }} />
                    </CustomTooltip>
                  )}
                  <Typography sx={{ fontWeight: 500 }}>View Backoffice</Typography>
                  <Box
                    sx={{ position: 'absolute', top: 30, left: 30, height: '1px', width: 'calc(100% -30èpx)', backgroundColor: '#BFC7C9' }}
                  />
                  <Box sx={{ mt: 2 }}>
                    {statusList.map((s, i) => {
                      let showLabel = false;

                      if (s.section !== statusList[!i ? i : i - 1].section) {
                        showLabel = true;
                      }
                      return (
                        <Box key={`status-of-${id}-${i}`} width="100%">
                          {showLabel && (
                            <Box className="flex ali-center" height={21}>
                              <Typography sx={{ ml: 6, color: '#B6BFC1' }}>{s.section}</Typography>
                              <Box sx={{ ml: 1, mr: -1.5, height: '1px', flexGrow: 1, backgroundColor: '#BFC7C9' }} />
                            </Box>
                          )}
                          <Box className="flex just-center" sx={{ position: 'relative', width: '100%' }}>
                            <CustomTooltip content="Step automatico">
                              <IconButton
                                sx={{ display: s.autoStep ? 'block' : 'none', position: 'absolute', left: 0, width: 40, height: 40 }}
                              >
                                <ErrorOutline sx={{ color: '#BFC7C9' }} />
                              </IconButton>
                            </CustomTooltip>
                            <Box
                              sx={{
                                position: 'absolute',
                                width: 16,
                                height: 20,
                                top: 0,
                                left: 50,
                                borderBottom: '1px solid #BFC7C9',
                                borderLeft: '1px solid #BFC7C9',
                                display: s.alternative ? 'block' : 'none'
                              }}
                            />
                            <MenuItem
                              disabled={s.autoStep || disabled}
                              value={s.state}
                              sx={{ pl: s.alternative ? 9 : 6, width: '100%', '&.Mui-disabled': { opacity: 1 } }}
                              onClick={() =>
                                openModal({
                                  title: 'Conferma azione',
                                  content: (
                                    <AskConfirmation
                                      confirmationText={`Confermi di voler modificare lo status dell'ordine ${orderCode} da "${translateOrderStatus(
                                        //@ts-ignore
                                        initialStatus,
                                        isProduct
                                        //@ts-ignore
                                      )}" a "${translateOrderStatus(s.state, isProduct)}"?`}
                                      onConfirmCallback={() => handleOrderChange(s.state as OrderState)}
                                    />
                                  )
                                })
                              }
                            >
                              <Box
                                sx={{
                                  minWidth: '170px',
                                  textAlign: 'center',
                                  px: 2,
                                  py: 0.5,
                                  borderRadius: '20px',
                                  ...style(s.state, statusList)
                                }}
                              >
                                {s.backofficeTranslation}
                              </Box>
                            </MenuItem>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                <Box width={270} className="flex dir-col ali-center" position="relative">
                  <Box position="absolute" top={-30} width="100%" height="108.7%" sx={{ backgroundColor: '#D9D9D93D', zIndex: 3 }} />
                  <Typography sx={{ fontWeight: 500 }}>View Platform</Typography>
                  <Box position="absolute" top={30} left={0} height="1px" width="100%" sx={{ backgroundColor: '#BFC7C9' }} />
                  <Box sx={{ mt: 2 }}>
                    {statusList.map((s, i) => {
                      let showLabel = false;

                      if (s.section !== statusList[!i ? i : i - 1].section) {
                        showLabel = true;
                      }
                      return (
                        <Box key={`status-of-${id}-${i}`} width="100%">
                          {showLabel && (
                            <Box className="flex ali-center" height={21}>
                              <Box sx={{ height: '1px', ml: -1.5, mr: 1, flexGrow: 1, backgroundColor: '#BFC7C9' }} />
                            </Box>
                          )}
                          <Box position="relative" width={'100%'} className="flex just-center">
                            <MenuItem value={s.state}>
                              <Box
                                sx={{
                                  minWidth: '170px',
                                  textAlign: 'center',
                                  px: 2,
                                  py: 0.5,
                                  borderRadius: '20px',
                                  ...style(s.state, statusList)
                                }}
                              >
                                {s.platformTranslation}
                              </Box>
                            </MenuItem>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            ) : (
              <>
                {statusList.map((s, i) => (
                  <MenuItem key={`status-of-${id}-${i}`} value={s.state}>
                    <Box
                      sx={{ minWidth: '170px', textAlign: 'center', px: 2, py: 0.5, borderRadius: '20px', ...style(s.state, statusList) }}
                    >
                      {s.translation}
                    </Box>
                  </MenuItem>
                ))}
              </>
            )}
          </Box>
        </Select>
      ) : (
        <Select
          variant="standard"
          labelId="transcription-status-label"
          id="transcription-status-select"
          disableUnderline
          value={initialStatus}
          MenuProps={{ sx: { zIndex: 2000 } }}
          sx={{
            '.MuiSelect-standard': selectedStatusStyle,
            '.MuiSelect-standard:focus': selectedStatusStyle,
            '.MuiInput-input': chipSize === 'small' ? { px: 1, py: 0, borderRadius: '20px' } : { px: 2, py: 0.5, borderRadius: '20px' },
            '.MuiInput-input:focus':
              chipSize === 'small' ? { px: 1, py: 0, borderRadius: '20px' } : { px: 2, pr: 3, py: 0.5, borderRadius: '20px' },
            '.MuiPopover-paper': { borderRadius: '20px!important' }
          }}
          onChange={handleChange}
        >
          {statusList.map((s, i) => (
            <MenuItem key={`status-of-${id}-${i}`} value={s.state}>
              <Box sx={{ minWidth: '60px', textAlign: 'center', px: 2, py: 0.5, borderRadius: '20px' }}>{s.translation}</Box>
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};
