import { Box, Typography } from '@mui/material';

export const ScreenShouldBeDesktop = () => (
  <Box
    sx={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      visibility: { xs: 'visible', md: 'hidden' },
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 99999999999,
      bgcolor: 'background.paper',
      alignItems: 'center',
      padding: 5
    }}
  >
    <Typography variant="h2">L'app è disponibile per schermi superiori ai 10".</Typography>
  </Box>
);
