import { Suspense, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container, Toolbar, Typography, Button, Grid2 } from '@mui/material';

// project import
import NavDrawer from './main-drawer';
import Header from './Header';
import useConfig from 'hooks/useConfig';
import { openDrawer, setRightDrawerOpen } from 'store/reducers/menu';
import { useError } from 'hooks/useError';
import { RightDrawer } from 'layout/MainLayout/right-drawer';
import { setFetchNotification } from 'store/reducers/helpers';
import { openSnackbar } from 'store/reducers/snackbar';
import { useEndpoint } from 'hooks/useEndpoint';
import { useSelector } from 'store';
import { createNotification } from 'utils/discriminators';

// types
import { RootStateProps } from 'types/root';
import { FallbackProps, withErrorBoundary } from 'react-error-boundary';
import { SingleData } from 'types/single-data';
import { NotificationType } from 'types/dto/notification.dto';

// assets
import IllustrationError from 'assets/images/illustrations/illustration_error.svg';
import Rotellina1 from 'assets/images/illustrations/rotellina_1.svg';
import Rotellina2 from 'assets/images/illustrations/rotellina_2.svg';
import { Backdrop } from './backdrop';

// ==============================|| MAIN LAYOUT ||============================== //

// componente di fallback, qualsiasi errore non gestito fa montare questo componente
const FallbackComponent = (fallbackProps: FallbackProps) => {
  const location = useLocation();
  const { sendErrorClient } = useError();
  const { resetErrorBoundary, error } = fallbackProps;
  let message: string = "C'è stato un errore nel caricamento della risorsa, si è pregati di ricaricare la pagina.";

  const pathnameRef = useRef(location.pathname);
  // const handleBackHome = () => {
  //   window.location.reload();
  // };
  useEffect(() => {
    if (location.pathname !== pathnameRef.current) {
      resetErrorBoundary();
    }
  }, [location.pathname]);

  // questo componente manda l'errore e tutti i suoi dati al server
  // in modo da tenere traccia di tutti gli errori del frontend
  useEffect(() => {
    sendErrorClient(error);
  }, []);

  return (
    <Box width={'100%'} position={'relative'}>
      <Container maxWidth={'lg'}>
        <Grid2
          container
          width={'100%'}
          minHeight={fallbackProps.error?.cause === 'loop' || fallbackProps.error?.cause === 'server_sync' ? '100dvh' : '70dvh'}
        >
          <Grid2 size={{ xs: 12, md: 6 }} className="flex just-center ali-center">
            <Box textAlign={'center'}>
              <Typography variant={'h6'} sx={{ mb: 3 }}>
                {fallbackProps.error?.cause === 'loop' || fallbackProps.error?.cause === 'server_sync'
                  ? fallbackProps.error.message
                  : message}
              </Typography>
              <Button onClick={() => resetErrorBoundary()} variant="contained">
                Riprova
              </Button>
            </Box>
          </Grid2>
          <Grid2
            size={{ xs: 12, md: 6 }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            sx={{
              img: {
                width: '100%',
                maxWidth: 300
              }
            }}
          >
            <img src={IllustrationError} alt={'error illustration'} />
          </Grid2>
        </Grid2>
      </Container>
      {/* questa parte è condizionale, nel caso di errori di tipo loop o se il server sta syncando delle modifiche */}
      {(fallbackProps.error?.cause === 'loop' || fallbackProps.error?.cause === 'server_sync') && (
        <>
          <Box
            sx={{
              img: {
                width: '100%',
                maxWidth: { xs: 150, md: 300 },
                zIndex: -1,
                position: 'absolute',
                top: 0,
                left: 0,
                objectFit: 'contain',
                transition: 'all 1s'
              }
            }}
          >
            <img src={Rotellina1} alt={'rotellina errore'} />
          </Box>

          <Box
            sx={{
              img: {
                width: '100%',
                maxWidth: { xs: 150, md: 300 },
                zIndex: -1,
                position: 'absolute',
                bottom: 0,
                right: 0,
                objectFit: 'contain',
                transition: 'all 1s'
              }
            }}
          >
            <img src={Rotellina2} alt={'rotellina errore'} />
          </Box>
        </>
      )}
    </Box>
  );
};
const OutletWithError = withErrorBoundary(Outlet, {
  FallbackComponent
});

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
  const { container, miniDrawer } = useConfig();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { fetchNotification } = useSelector((state) => state.helpers);
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen, rightDrawerOpen } = menu;

  // questo stato controlla se il drawer è aperto in pieno o ridotto a una barra
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);

  // le notifiche le prendevo qui, sapendo che il componente era sempre montato
  // puoi tranquillamente scegliere un'altra soluzione
  const getNotification = useEndpoint<SingleData<NotificationType>, 'get'>({
    method: 'get',
    endpoint: `/notifications/${fetchNotification?.id}`,
    queryKey: `get-notification-${fetchNotification?.id}`,
    options: {
      enabled: false,
      onSuccess: ({ data }) => {
        dispatch(
          openSnackbar({
            message: createNotification(data.data),

            open: true,
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            key: `info`
          })
        );
        dispatch(setFetchNotification(undefined));
      }
    }
  });

  // mostra/rimuove il loader iniziale
  useLayoutEffect(() => {
    setTimeout(() => {
      document.getElementById('bootstrap-loader')?.classList.add('remove');
      setTimeout(() => {
        document.getElementById('bootstrap-loader')?.remove();
      }, 200);
    }, 1000);
  }, []);

  // questo rende il drawer responsive al cambio di dimensione
  // rispettata una size minima il drawer è aperto totalmente
  // quando si riduce la dimensione dello schermo il drawer si minimizza
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
  }, [matchDownLG]);

  // ascolta il socket delle notifiche
  useEffect(() => {
    // se deve fetchare una notifica e ho i dati dell'utente attuale
    if (fetchNotification && user) {
      // e la notifica non è generata dall'utente stesso
      if (fetchNotification.userId !== user.id) {
        // fai una get di quella notifica
        getNotification.refetch();
        // altrimenti svuota la notifica di cui fare la get, perchè non devi
      } else dispatch(setFetchNotification(undefined));
    }
    // dipendenza: oggetto dello store che definisce la notifica da fetchare
  }, [fetchNotification]);

  // funzione per gestire l'apertura/minimizzazione del drawer
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // funzione per gestire solo la chiusura del drawer
  const handleRightDrawerClose = () => {
    if (rightDrawerOpen) {
      dispatch(setRightDrawerOpen(false));
    }
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', zIndex: 2000 }}>
      {/* header dell'intera applicazione */}
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      {/* drawer sinistro con le voci di menù */}
      <NavDrawer open={open} handleDrawerToggle={handleDrawerToggle} />
      {/* drawer destro in cui si gestiscono i task, le note e le notifiche */}
      <RightDrawer open={rightDrawerOpen} />
      <Box component="main" sx={{ width: 'calc(100% - 260px)', flexGrow: 1 }}>
        <Toolbar sx={{ height: '74px' }} />

        {/* container della pagina interno a tutto il layout */}
        <Container
          maxWidth={false}
          sx={{
            ...(container && { px: '0!important' }),
            position: 'relative',
            overflowY: rightDrawerOpen ? 'hidden' : 'auto',
            minHeight: 'calc(100vh - 74px)',
            display: 'flex',
            flexDirection: 'column'
          }}
          onClick={handleRightDrawerClose}
        >
          {/* 
              react suspense, che include l'outlet per gestire le rotte
              e l'invio di errori frontend al server 
          */}
          <Suspense fallback={<div>ciccio pasticcio</div>}>
            <OutletWithError />
          </Suspense>
          {/* backdrop che si attiva all'apertura del drawer destro */}
          <Backdrop />
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
